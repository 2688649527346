import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params) {
        // Store the query params in localStorage
        localStorage.setItem('user', JSON.stringify(params));
      }
      // Navigate to the /quote route
      this.router.navigate(['/quote']);
    });
  }

}

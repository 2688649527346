import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BadRequestComponent } from './components/bad-request/bad-request.component';
import { FellohFailureComponent } from './components/felloh-failure/felloh-failure.component';
import { FellohSuccessComponent } from './components/felloh-success/felloh-success.component';
import { BookingDetailsComponent } from './components/booking-details/booking-details.component';
import { ItineraryComponent } from './components/itinerary/itinerary.component';
import { QuoteDetailsComponent } from './components/quote/quote.component';

const routes: Routes = [
  { path: 'quote/:customerID/:quoteID', component: QuoteDetailsComponent },
  { path: 'okFelloh', component: FellohSuccessComponent },
  { path: 'okFelloh/:id/:u/:p/:branchName/:branchLogo', component: FellohSuccessComponent },
  { path: 'cancelFelloh/:branchName/:branchLogo/:status', component: FellohFailureComponent },
  { path: 'cancelFelloh/:branchName/:branchLogo/:status/:paymentId', component: FellohFailureComponent },
  { path: 'booking/:publicRequest/:publicKey', component: BookingDetailsComponent },
  { path: 'itinerary/:publicRequest/:publicKey', component: ItineraryComponent },
  { path: '', component: BadRequestComponent },
  { path: '**', component: BadRequestComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

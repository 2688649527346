import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FellohService {

  constructor(private http: HttpClient) { }

  /* DEPRECATED [V1]
  async getPaymentStatus(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn}`, 'Content-Type': 'application/json'};
    const output = await this.http.get(environment.apiFelloh + 'checkout-payment/payment-status/' +
    request.id, {headers}).toPromise();
    return output;
  }

  async getPaymentStatusById(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn}`, 'Content-Type': 'application/json'};
    const output = await this.http.get(environment.apiFelloh + 'checkout-payment/status/' + request.id, {headers}).toPromise();
    return output;
  }
  */
  async getPaymentStatusV2(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiFellohNew + 'agent/transactions', body, { headers }).toPromise();
    return output;
  }

  async createEcommerce(request: any, tokenIn: any): Promise<object> {
    const headers = { Authorization: `Bearer ${tokenIn}`, 'Content-Type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.put(environment.apiFellohNew + 'agent/ecommerce', body, { headers }).toPromise();
    return output;
  }
}

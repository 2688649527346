<div class="container" *ngIf="accessDenied">
  <div class="messageBox">
    <h1>Unknown Request</h1>
  </div>
</div>


<div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch" *ngIf="!accessDenied">
  <!-- Header (Navbar) -->
  <mat-toolbar color="primary" class="navbar">
    <div class="container">
      <span class="logo">
        <img src="https://cdn.tprofile.com/e7a688da-274a-428f-d9ad-08dcdc8b698f/52e3006e-6c0b-4d58-51d7-08dcdc8b6995/the-cruise-club.png"/>
      </span>
    </div>
  </mat-toolbar>

  <!-- Hero Banner Section -->
  <section class="region-header">
    <div class="block block-quote-banner">
      <div class="container-full-width">
        <img
          alt="9 Night Southern Caribbean Cruise"
          class="img-responsive"
          src="https://cdn.tprofile.com/content/Regions/455_Oranjestad/BANNER_oranjestad_1.jpg"
        />
      </div>
    </div>
    <div class="padding-block-lg secondary-block block-travel-info">
      <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="20px">
        <h1 class="block-title travel-title">9 Night Southern Caribbean Cruise</h1>

        <div fxLayout="row wrap" fxLayoutAlign="center center" class="travel-info-container">
          <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25" class="travel-info-item">
            <div class="grid-icon-text">
              <div class="grid-icon">
                <mat-icon class="icon">directions_boat</mat-icon>
              </div>
              <div class="grid-text">
                <div class="travel-info-title">ONBOARD</div>
                <div class="travel-info-item-title">Grandeur of the Seas</div>
              </div>
            </div>
          </div>
        
          <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25" class="travel-info-item">
            <div class="grid-icon-text">
              <div class="grid-icon">
                <mat-icon class="icon">account_balance</mat-icon>
              </div>
              <div class="grid-text">
                <div class="travel-info-title">OPERATOR</div>
                <div class="travel-info-item-title">Royal Caribbean International</div>
              </div>
            </div>
          </div>
        
          <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25" class="travel-info-item">
            <div class="grid-icon-text">
              <div class="grid-icon">
                <mat-icon class="icon">schedule</mat-icon>
              </div>
              <div class="grid-text">
                <div class="travel-info-title">DURATION</div>
                <div class="travel-info-item-title">12 Nights</div>
              </div>
            </div>
          </div>
        
          <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25" class="travel-info-item">
            <div class="grid-icon-text">
              <div class="grid-icon">
                <mat-icon class="icon">calendar_today</mat-icon>
              </div>
              <div class="grid-text">
                <div class="travel-info-title">DEPARTING</div>
                <div class="travel-info-item-title">07/01/2025</div>
              </div>
            </div>
          </div>
        </div>
        
        
      </div>
    </div>
  </section>
  
  <div class="main-container">
    <!-- Left Column -->
    <div class="left-column">
      <!-- Quote Information -->
      <div class="info-section">
        <div class="info-box">
          <div class="info-box-label">Name</div>
          <div class="info-box-value">Gary Gillespie</div>
        </div>
        <div class="info-box">
          <div class="info-box-label">Reference</div>
          <div class="info-box-value">CRCL24102942</div>
        </div>
        <div class="info-box">
          <div class="info-box-label">Travel Specialist</div>
          <div class="info-box-value">Gary Gillespie</div>
        </div>
        <div class="info-box">
          <div class="info-box-label">Passengers</div>
          <div class="info-box-value">2 Adults</div>
        </div>
      </div>
  
      <!-- Itinerary Section -->
      <section class="itinerary">
        <h2>Your Itinerary</h2>
      
        <div *ngFor="let item of itineraryItems" class="itinerary-item">
          <div class="itinerary-icon">
            <mat-icon>{{ item.icon }}</mat-icon>
          </div>
          <div class="itinerary-details">
            <h4>{{ item.title }}</h4>
            <p *ngIf="item.overview">{{ item.overview }}</p>

            <div class="image-container" *ngIf="item.image">
              <img [src]="item.image" [alt]="item.title" class="destination-image" />
            </div>
            
            <p *ngIf="item.duration" class="duration"><strong>{{ item.duration }}</strong></p>
            <p *ngIf="item.airline" class="airline"><strong>{{ item.airline }}</strong></p>
            <p *ngIf="item.cabinClass" class="cabin-class"><strong>{{ item.cabinClass }}</strong></p>
            <p *ngIf="item.flightNumber" class="flight-number"><strong>{{ item.flightNumber }}</strong></p>
            <p *ngIf="item.checkInTime" class="check-in"><strong>Check-In Time:</strong> {{ item.checkInTime }}</p>
            <p *ngIf="item.baggageAllowance" class="baggage"><strong>{{ item.baggageAllowance }}</strong></p>
            <p *ngIf="item.departure" class="check-in"><strong>Depart: {{ item.departure }} - Arrive: {{ item.arrival }}</strong></p>

            <p *ngIf="item.location" class="location"><strong>{{ item.location }}</strong></p>
            <p *ngIf="item.checkIn" class="check-in"><strong>Check-In:</strong> {{ item.checkIn }}</p>
            <p *ngIf="item.checkOut" class="check-out"><strong>Check-Out:</strong> {{ item.checkOut }}</p>
            <p *ngIf="item.nights" class="nights"><strong>{{ item.nights }}</strong></p>
            <p *ngIf="item.board" class="board"><strong>{{ item.board }}</strong></p>
            <p *ngIf="item.roomType" class="room-type"><strong>{{ item.roomType }}</strong></p>
          </div>
        </div>

        <!-- Map Section -->
        <div class="map-container">
          <h3>Route Map</h3>
          <div id="map"></div>
        </div>

        <!-- What's Included Section -->
        <div class="whats-included">
          <h3>What's Included...</h3>
          <ul>
            <li *ngFor="let include of whatsIncluded">
              <i class="fas fa-check"></i> {{ include }}
            </li>
          </ul>
        </div>

        <!-- Price Section -->
        <div class="price-container">
          <h3>Junior Suite</h3>
          <div class="price">
            <div class="info-box-label">
              Total Price
            </div>
            <div class="info-box-value big-value">
              {{ totalPrice | currency: 'GBP' }}
            </div>
          </div>
        </div>
      </section>
    </div>
  
    <!-- Right Column -->
    <div class="right-column">
      <div class="ship-section">
        <img src="https://widgety-assets.s3.amazonaws.com/2024/11/13/20/49/47/4cf33f9e-7ec4-4205-9436-9e88983fb404/RCI,%20Grandeur%20of%20the%20Seas,%20Exterior.jpg" alt="Grandeur of the Seas" class="ship-image" />
        <h2>Your Ship: Grandeur of the Seas</h2>
        <mat-accordion>
          <mat-expansion-panel *ngFor="let panel of panels">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ panel.title }}</mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ panel.content }}</p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <div class="destination-section">
        <div class="destination-card" style="width: 100%;">
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/iuVjWqSFweE" frameborder="0" allowfullscreen></iframe>
        </div>

        <div class="destination-card" *ngFor="let destination of destinations">
          <div *ngIf="destination.image" class="image-container">
            <img [src]="destination.image" [alt]="destination.title" class="destination-image" />
          </div>
          <h3>{{ destination.title }}</h3>
          <p *ngIf="destination.description">{{ destination.description }}</p>
          <button class="cta-button" *ngIf="destination.hasQuestion">{{ destination.questionLabel }}</button>
        </div>
      </div>
          
      <section class="feedback-section">
        <h2>Feedback</h2>
        <p style="margin-bottom: 10px;">Alternatively, use the feedback form below to confirm your booking, request or provide other feedback.</p>
        <p style="margin-bottom: 10px;"><strong>Your Travel Specialist</strong></p>
        <p class="specialist-name">Gary Gillespie</p>
      
        <form (submit)="submitFeedback()" class="feedback-form">
          <div class="feedback-options">
            <label>
              <input type="radio" name="feedbackType" value="book" [(ngModel)]="feedbackType" />
              <span class="option-book">I’d Like to Book</span>
            </label>
            <label>
              <input type="radio" name="feedbackType" value="amend" [(ngModel)]="feedbackType" />
              <span class="option-amend">Please Amend</span>
            </label>
          </div>          
      
          <div class="comments-section">
            <label for="comments">Any Comments</label>
            <textarea id="comments" rows="4" [(ngModel)]="comments" name="comments"></textarea>
          </div>
      
          <button type="submit" class="submit-button">Submit Feedback</button>
        </form>
      </section>
      
      <section class="travel-specialist-section">
        <h2>Your Travel Specialist</h2>
        <div class="travel-specialist-card">
          <div class="specialist-avatar">
            <i class="fas fa-user-circle"></i>
          </div>
          <div class="specialist-details">
            <p class="specialist-name">Gary Gillespie</p>
            <p class="specialist-email">
              <a href="mailto:gary.gillespie@ite.travel">gary.gillespie@ite.travel</a>
            </p>
          </div>
        </div>
      </section>
      
    </div>
    
  </div>
</div>
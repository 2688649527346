import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.css', '../../customerFace.css'],
  animations: [
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class BookingDetailsComponent implements OnInit {

  pageLoaded = false;

  fullBookingSkeleton: any = {};
  bookingData: any = {}; // Variable which contains booking data only (without element)
  costingsData: any = {}; // Holds costing-only (without full booking info etc)
  chartSelected: any = {}; // Whenever user clicks on the element in the timeline chart, element's data is loaded here
  fellohLinks: any = new MatTableDataSource<any>(); // This is where we will dump all payment link URLs
  addnDataView: any = 0; // Variable used to 'switch' between rooms / cabins
  currentBookingUrl: any = ''; // This variable will hold full URL which user can go back to (e.g from Itinerary page)

  fellohLinksColumns = ['branchName', 'custName', 'expiryDate', 'amount'];

  // Visual stuff variables here..
  progressBarValue: any = 0;
  daysToDeptDate: any = 0;
  balanceDue: any;
  totalReceipted: any;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('timeLineChart') timeLineChart: any;
  @ViewChild('chartDialog') chartDialog!: TemplateRef<any>;
  openedDialog: any = ''; // Variable which dialogs are assigned to when clicking on the travel timeline element

  // Chart variables below
  public chartOptions: Partial<any> = {
    series: [],
    chart: {
      type: 'rangeBar',
      background: '#ff22f',
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      toolbar: {
        tools: {
          reset: false,
          download: false,
          zoom: false,
          pan: false,
          zoomin: false,
          zoomout: false
        }
      },
      width: '90%',
      height: '400',
      autoSelected: 'pan',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      },
      events: {
        click: (event: any, chartContext: any, config: any) => {
          if (config.dataPointIndex >= 0) {
            this.addnDataView = 0;
            this.chartSelected = config.config.series[config.seriesIndex].data[config.dataPointIndex];
            this.openedDialog = this.dialog.open(this.chartDialog, { autoFocus: false });
          }
        }
      },
    },
    grid: {
      row: {
        colors: ['#f8f8f8', '#e0e0e0'],
      },
      column: {
        colors: ['#f8f8f8', 'transparent'],
      },
      xaxis: {
        lines: {
          show: true
        }
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: false,
        rangeBarOverlap: false,
        barHeight: '60%',
        dataLabels: {
          hideOverflowingLabels: true,
          color: 'red'
        },
      }
    },
    tooltip: {
      // tslint:disable-next-line:typedef
      custom({ series, seriesIndex, dataPointIndex, w }: any) {
        const from = new Date(w.config.series[seriesIndex].data[dataPointIndex].y[0]);
        const to = new Date(w.config.series[seriesIndex].data[dataPointIndex].y[1]);

        return (
          '<div style="color: #717FDA; margin: 3.5px;"><b>' +
          w.config.series[seriesIndex].data[dataPointIndex].niceType +
          ' <span style=\'text-transform: none;\'></span></b></div>' +
          '<div style="color: #202020; margin: 3.5px;"><i>' +
          from.toLocaleDateString() + ' - ' + to.toLocaleDateString() +
          '</i></div>'
        );
      }
    },
    xaxis: {
      type: 'datetime',
      autorange: true,
      position: 'top',
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      autorange: false,
      labels: {
        align: 'center',
        minWidth: 0,
        maxWidth: 500,
        style: {
          fontFamily: 'Montserrat',
          fontWeight: 600
        },
        formatter: (value: any) => {
          if (value.toString().split('|').length > 1) {
            const arrayToReturn = []; // That's where we'll enter new text line
            const supplierNameFull = value.toString().split('|')[0]; // Get the full name

            let supplierName = ''; // That's where we'll assign supplier names from 'full string' in a loop
            let supplierNameDone = ''; // That's where we'll merge the whole supplier name

            let count = 0; // This will allow us to escape from never ending loop
            while (supplierNameDone !== supplierNameFull && count < 7) {
              // Split the name to 20 characters (+ finish current word)
              supplierName = supplierNameFull.replace(supplierNameDone, '').replace(/^(.{15}[^\s]*).*/, '$1');
              supplierNameDone = supplierNameDone + supplierName; // Add piece to the puzzle
              arrayToReturn.push(supplierName); // Add text line to array list
              count++;
            }
            return '';
          } else {
            return '';
          }
        }
      },
    },
    legend: {
      show: false
    },
    colors: ['#4D5FD1']
  };

  public pieChartOptions1: Partial<any> = {
    series: [],
    chart: {
      width: '100%',
      type: 'donut',
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    labels: ['Paid', 'Due'],
    colors: ['#4D5FD1', '#a6a6a6'],
    stroke: {
      show: false,
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          if (value >= 0) {
            return '£' + value.toFixed(2);
          } else {
            return '-£' + (value.toFixed(2) * -1);
          }
        }
      }
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: false,
        offsetX: 0,
        offsetY: 0,
        customScale: 0.9,
      }
    },
    legend: {
      show: true,
      position: 'top',
    },
    dataLabels: {
      // tslint:disable-next-line:typedef
      formatter(val: any, opts: any) {
        const value = opts.w.config.series[opts.seriesIndex];
        if (value >= 0) {
          return '£' + value.toFixed(2);
        } else {
          return '-£' + (value.toFixed(2) * -1);
        }
      }
    }
  };

  constructor(private router: Router, private route: ActivatedRoute,
              private customerService: CustomerService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const request = { publicRequest: decodeURI(params.publicRequest), publicKey: decodeURI(params.publicKey) };
      this.currentBookingUrl = '/booking/' + params.publicRequest + '/' + params.publicKey;

      this.recalculateBookingsValues(request).then(res => {
        if (res === 'OK') {
          this.populateChart();
          this.populatePieChart(this.balanceDue, this.totalReceipted);
          this.pageLoaded = true;
        } else {
          this.router.navigate(['/']);
        }
      });
    });
  }

  recalculateBookingsValues(request: any): any {
    return new Promise((resolve, reject) => {
      this.customerService.getBookingFull(request).then((skeleton: any) => {
        if (skeleton.status === 'OK' && skeleton.bookingData.status !== 'no such booking') {
          this.fullBookingSkeleton = skeleton;
          this.bookingData = skeleton.bookingData;
          skeleton.elementData.forEach((element: any) => { element.detailRow = true; });
          this.costingsData.data = skeleton.elementData;

          this.balanceDue = this.bookingData.custPrice - this.bookingData.totalReceiptedCharged;
          this.totalReceipted = this.bookingData.totalReceiptedCharged;

          this.costingsData.data.forEach((costing: any) => {

            costing.accoms = costing.accoms.filter((element: any) => {
              return element.accomStatus !== 'cancelled';
            });

            costing.flights = costing.flights.filter((element: any) => {
              return element.flightStatus !== 'cancelled';
            });

            costing.carhires = costing.carhires.filter((element: any) => {
              return element.carHireStatus !== 'cancelled';
            });

            costing.carparks = costing.carparks.filter((element: any) => {
              return element.carparkStatus !== 'cancelled';
            });

            costing.attractions = costing.attractions.filter((element: any) => {
              return element.attractionStatus !== 'cancelled';
            });

            costing.miscs = costing.miscs.filter((element: any) => {
              return element.miscStatus !== 'cancelled';
            });

            costing.cruises = costing.cruises.filter((element: any) => {
              return element.cruiseStatus !== 'cancelled';
            });

            costing.transfers = costing.transfers.filter((element: any) => {
              return element.transferStatus !== 'cancelled';
            });

            costing.trains = costing.trains.filter((element: any) => {
              return element.trainStatus !== 'cancelled';
            });
          });

          // Get current date in ISO format..
          const todaysDate = new Date().toISOString().split('T')[0];
          // Calculate the the number of days between today and the departure date
          let differenceInDaysTodaysDate = ((new Date(this.bookingData.deptDate) as any) - (new Date(todaysDate) as any));
          differenceInDaysTodaysDate = (differenceInDaysTodaysDate) / (1000 * 3600 * 24);
          // If the departre date is today or it already happened, the progress bar value will be 100% (0 days until dept date..)
          if (differenceInDaysTodaysDate <= 0) { this.progressBarValue = 100; this.daysToDeptDate = 0; }
          else {
            // Calculate the nubmer of days between booking's departure date and its booking date
            let differenceInDaysBookDate = ((new Date(this.bookingData.deptDate) as any) - (new Date(this.bookingData.bookingDate) as any));
            differenceInDaysBookDate = (differenceInDaysBookDate) / (1000 * 3600 * 24);
            // Calculate the 'date completion' in percantages below
            this.progressBarValue = ((differenceInDaysBookDate - differenceInDaysTodaysDate) / differenceInDaysBookDate) * 100;
            this.daysToDeptDate = differenceInDaysTodaysDate; // Set the number of days until departure date below..
          }

          // Filter each Felloh link and generate the URL for each ACTIVE one then dump it to the array list
          const fellohLinks = [];
          if (Array.isArray(skeleton.fellohLinks)) {
            skeleton.fellohLinks.forEach((link: any) => {
              if (link.status == 'active') {
                if (link.currency === 'GBX') { link.currency = 'GBP'; link.amount = link.amount / 100; }
                else if (link.currency === 'USX') { link.currency = 'USD'; link.amount = link.amount / 100; }
                else if (link.currency === 'EUX') { link.currency = 'EUR'; link.amount = link.amount / 100; }
                link.url = environment.fellohPayGate + link.id; fellohLinks.push(link);
              }
            });
          }
          this.fellohLinks.data = fellohLinks;

          this.reorderDivElements(skeleton.branchDetails); // Call method which will reorder div elements (based on branch setup)
          resolve('OK');
        } else if (skeleton.status === 'OK' && skeleton.bookingData.status === 'no such booking') {
          this.pageLoaded = true;
          resolve('No Booking');
        } else if (skeleton.status !== 'OK') {
          this.pageLoaded = true;
          resolve('No Access');
        }
      }).catch((error: any) => {
        resolve('Error');
      });
    });
  }

  populateChart(): void {
    const chartData: any = [];
    const elementData: any = { name: '', data: [] };

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.fullBookingSkeleton.elementData.length; i++) {
      elementData.name = [this.fullBookingSkeleton.elementData[i].supplierName, this.fullBookingSkeleton.elementData[i].supplierReference];
      if (this.fullBookingSkeleton.elementData[i].flights.length > 0) {
        this.fullBookingSkeleton.elementData[i].flights.forEach((element: any) => {
          elementData.data.push(
            {
              x: this.fullBookingSkeleton.elementData[i].supplierName + '|' + this.fullBookingSkeleton.elementData[i].supplierReference,
              y: [
                new Date(element.deptDate + ' 01:00:00').getTime(),
                new Date(element.returnDate + ' 23:59:59').getTime()],
              type: 'flight',
              niceType: 'Flight',
              fillColor: this.getSeriesColour(element.flightStatus),
              element,
              costing: this.fullBookingSkeleton.elementData[i]
            });
        });
      }
      if (this.fullBookingSkeleton.elementData[i].accoms.length > 0) {
        this.fullBookingSkeleton.elementData[i].accoms.forEach((element: any) => {
          const returnDate = new Date(element.checkInDate);
          returnDate.setDate(returnDate.getDate() + Number(element.numNights));
          const dd = returnDate.getDate(); const mm = returnDate.getMonth() + 1; const y = returnDate.getFullYear();
          const retDate = this.zeroBeforeNo(y) + '-' + this.zeroBeforeNo(mm) + '-' + this.zeroBeforeNo(dd);

          elementData.data.push(
            {
              x: this.fullBookingSkeleton.elementData[i].supplierName + '|' + this.fullBookingSkeleton.elementData[i].supplierReference,
              y: [
                new Date(element.checkInDate + ' 01:00:00').getTime(),
                new Date(retDate + ' 23:59:59').getTime()],
              type: 'accommodation',
              niceType: 'Accommodation',
              fillColor: this.getSeriesColour(element.accomStatus),
              element,
              costing: this.fullBookingSkeleton.elementData[i]
            });
        });
      }
      if (this.fullBookingSkeleton.elementData[i].carhires.length > 0) {
        this.fullBookingSkeleton.elementData[i].carhires.forEach((element: any) => {
          elementData.data.push(
            {
              x: this.fullBookingSkeleton.elementData[i].supplierName + '|' + this.fullBookingSkeleton.elementData[i].supplierReference,
              y: [
                new Date(element.pickUpDate + ' 01:00:00').getTime(),
                new Date(element.dropOffDate + ' 23:59:59').getTime()],
              type: 'carHire',
              niceType: 'Car Hire',
              fillColor: this.getSeriesColour(element.carHireStatus),
              element,
              costing: this.fullBookingSkeleton.elementData[i]
            });
        });
      }
      if (this.fullBookingSkeleton.elementData[i].carparks.length > 0) {
        this.fullBookingSkeleton.elementData[i].carparks.forEach((element: any) => {
          let startDate = element.startDate; // Assign date time to a variable
          let endDate = element.endDate; // Assign date time to a variable

          // In case these two date times are the same, they won't appear on the travel timeline
          // We need to set fake date and time to them (1AM -> 23PM) as if it was happening all day
          if (startDate === endDate) {
            startDate = element.startDate.substring(0, 4) + '-' + element.startDate.substring(5, 7) + '-' +
              element.startDate.substring(8, 10) + ' 01:00:00';
            endDate = element.endDate.substring(0, 4) + '-' + element.endDate.substring(5, 7) + '-' +
              element.endDate.substring(8, 10) + ' 23:59:59';
          }

          elementData.data.push(
            {
              x: this.fullBookingSkeleton.elementData[i].supplierName + '|' + this.fullBookingSkeleton.elementData[i].supplierReference,
              y: [
                new Date(startDate).getTime(),
                new Date(endDate).getTime()],
              type: 'carParking',
              niceType: 'Car Parking',
              fillColor: this.getSeriesColour(element.carparkStatus),
              element,
              costing: this.fullBookingSkeleton.elementData[i]
            });
        });
      }
      if (this.fullBookingSkeleton.elementData[i].attractions.length > 0) {
        this.fullBookingSkeleton.elementData[i].attractions.forEach((element: any) => {
          let startDate = element.startDateTime; // Assign date time to a variable
          let endDate = element.endDateTime; // Assign date time to a variable

          // In case these two date times are the same, they won't appear on the travel timeline
          // We need to set fake date and time to them (1AM -> 23PM) as if it was happening all day
          if (startDate === endDate) {
            startDate = element.startDateTime.substring(0, 4) + '-' + element.startDateTime.substring(5, 7) + '-' +
              element.startDateTime.substring(8, 10) + ' 01:00:00';
            endDate = element.endDateTime.substring(0, 4) + '-' + element.endDateTime.substring(5, 7) + '-' +
              element.endDateTime.substring(8, 10) + ' 23:59:59';
          }

          elementData.data.push(
            {
              x: this.fullBookingSkeleton.elementData[i].supplierName + '|' + this.fullBookingSkeleton.elementData[i].supplierReference,
              y: [
                new Date(startDate).getTime(),
                new Date(endDate).getTime()],
              type: 'attraction',
              niceType: 'Attraction',
              fillColor: this.getSeriesColour(element.attractionStatus),
              element,
              costing: this.fullBookingSkeleton.elementData[i]
            });
        });
      }
      if (this.fullBookingSkeleton.elementData[i].miscs.length > 0) {
        this.fullBookingSkeleton.elementData[i].miscs.forEach((element: any) => {
          let startDate = element.startDateTime; // Assign date time to a variable
          let endDate = element.endDateTime; // Assign date time to a variable

          // In case these two date times are the same, they won't appear on the travel timeline
          // We need to set fake date and time to them (1AM -> 23PM) as if it was happening all day
          if (startDate === endDate) {
            startDate = element.startDateTime.substring(0, 4) + '-' + element.startDateTime.substring(5, 7) + '-' +
              element.startDateTime.substring(8, 10) + ' 01:00:00';
            endDate = element.endDateTime.substring(0, 4) + '-' + element.endDateTime.substring(5, 7) + '-' +
              element.endDateTime.substring(8, 10) + ' 23:59:59';
          }

          elementData.data.push(
            {
              x: this.fullBookingSkeleton.elementData[i].supplierName + '|' + this.fullBookingSkeleton.elementData[i].supplierReference,
              y: [
                new Date(startDate).getTime(),
                new Date(endDate).getTime()],
              type: 'miscellaneous',
              niceType: 'Miscellaneous',
              fillColor: this.getSeriesColour(element.miscStatus),
              element,
              costing: this.fullBookingSkeleton.elementData[i]
            });
        });
      }
      if (this.fullBookingSkeleton.elementData[i].trains.length > 0) {
        this.fullBookingSkeleton.elementData[i].trains.forEach((element: any) => {

          let startDate = element.departDateTime; // Assign date time to a variable
          let endDate = element.arriveDateTime; // Assign date time to a variable

          // In case these two date times are the same, they won't appear on the travel timeline
          // We need to set fake date and time to them (1AM -> 23PM) as if it was happening all day
          if (startDate === endDate) {
            startDate = element.departDateTime.substring(0, 4) + '-' + element.departDateTime.substring(5, 7) + '-' +
              element.departDateTime.substring(8, 10) + ' 01:00:00';
            endDate = element.arriveDateTime.substring(0, 4) + '-' + element.arriveDateTime.substring(5, 7) + '-' +
              element.arriveDateTime.substring(8, 10) + ' 23:59:59';
          }

          elementData.data.push(
            {
              x: this.fullBookingSkeleton.elementData[i].supplierName + '|' + this.fullBookingSkeleton.elementData[i].supplierReference,
              y: [
                new Date(startDate).getTime(),
                new Date(endDate).getTime()],
              type: 'train',
              niceType: 'Train',
              fillColor: this.getSeriesColour(element.trainStatus),
              element,
              costing: this.fullBookingSkeleton.elementData[i]
            });
        });
      }
      if (this.fullBookingSkeleton.elementData[i].transfers.length > 0) {
        this.fullBookingSkeleton.elementData[i].transfers.forEach((element: any) => {

          let startDate = element.pickUpDateTime; // Assign date time to a variable
          let endDate = element.dropOffDateTime; // Assign date time to a variable

          // In case these two date times are the same, they won't appear on the travel timeline
          // We need to set fake date and time to them (1AM -> 23PM) as if it was happening all day
          if (startDate === endDate) {
            startDate = element.pickUpDateTime.substring(0, 4) + '-' + element.pickUpDateTime.substring(5, 7) + '-' +
              element.pickUpDateTime.substring(8, 10) + ' 01:00:00';
            endDate = element.dropOffDateTime.substring(0, 4) + '-' + element.dropOffDateTime.substring(5, 7) + '-' +
              element.dropOffDateTime.substring(8, 10) + ' 23:59:59';
          }

          elementData.data.push(
            {
              x: this.fullBookingSkeleton.elementData[i].supplierName + '|' + this.fullBookingSkeleton.elementData[i].supplierReference,
              y: [
                new Date(startDate).getTime(),
                new Date(endDate).getTime()],
              type: 'transfer',
              niceType: 'Transfer',
              fillColor: this.getSeriesColour(element.transferStatus),
              element,
              costing: this.fullBookingSkeleton.elementData[i]
            });
        });
      }
      if (this.fullBookingSkeleton.elementData[i].cruises.length > 0) {
        this.fullBookingSkeleton.elementData[i].cruises.forEach((element: any) => {
          elementData.data.push(
            {
              x: this.fullBookingSkeleton.elementData[i].supplierName + '|' + this.fullBookingSkeleton.elementData[i].supplierReference,
              y: [
                new Date(element.deptDate).getTime(),
                new Date(element.returnDate).getTime()],
              type: 'cruise',
              niceType: 'Cruise',
              fillColor: this.getSeriesColour(element.cruiseStatus),
              element,
              costing: this.fullBookingSkeleton.elementData[i]
            });
        });
      }
    }
    chartData.push(elementData);
    this.chartOptions.series = chartData;
  }

  populatePieChart(balanceDue: any, totalReceipted: any): void {
    const chartData = [Number(totalReceipted), Number(balanceDue)];
    this.pieChartOptions1.series = chartData;
  }

  reorderDivElements(branchData: any): void {
    // Assign all 'orderable' class into variables first
    const bookingRefHeading: any = document.getElementsByClassName('bookingReferenceHeading')[0];
    const deptCountdown: any = document.getElementsByClassName('dpeartureCountdown')[0];
    const containerBooking: any = document.getElementsByClassName('containerBooking')[0];
    const fellohLinks: any = document.getElementsByClassName('fellohLinks')[0];
    const charts: any = document.getElementsByClassName('charts')[0];

    // Change the order of divs below
    try {
      bookingRefHeading.style.order = branchData.publicElementsOrder.split(';')[0].split(',').indexOf('1');
      deptCountdown.style.order = branchData.publicElementsOrder.split(';')[0].split(',').indexOf('2');
      containerBooking.style.order = branchData.publicElementsOrder.split(';')[0].split(',').indexOf('3');
      fellohLinks.style.order = branchData.publicElementsOrder.split(';')[0].split(',').indexOf('4');
      charts.style.order = branchData.publicElementsOrder.split(';')[0].split(',').indexOf('5');
    } catch {
      bookingRefHeading.style.order = 1;
      deptCountdown.style.order = 2;
      containerBooking.style.order = 3;
      fellohLinks.style.order = 4;
      charts.style.order = 5;
    }
  }

  goToItinerary(): void {
    const itineraryUrl = this.currentBookingUrl.replace('/booking/', '/itinerary/');

    this.router.navigateByUrl(itineraryUrl, { state: { skeleton: this.fullBookingSkeleton, bookingUrl: this.currentBookingUrl } });
  }

  openFellohLink(link: any): void {
    window.open(link, '_blank');
  }

  getSeriesColour(status: any): string {
    if (status === 'enquiry') {
      return '#ce983a';
    } else if (status === 'booking') {
      return '#4D5FD1';
    } else if (status === 'cancelled') {
      return '#a6a6a6';
    } else {
      return 'black';
    }
  }

  zeroBeforeNo(inputNo: any): any {
    if (inputNo < 10) {
      inputNo = '0' + inputNo;
    }
    return inputNo;
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.timeLineChart.updateOptions({});
  }
}

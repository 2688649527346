<div class="container">
  <div class="messageBox">
    <p *ngIf="branchLogo != 'null'"><img src="https://sings-media.s3.eu-west-2.amazonaws.com/logo/{{branchLogo}}" width="155px" style="mix-blend-mode: multiply;"></p>

    <h1 *ngIf="status == 'declined'">Failed transaction</h1>
    <h1 *ngIf="status == 'timeout'">Payment in progress..</h1>
    <h1 *ngIf="status == 'error'">Oops, something went wrong..</h1>

    <h2 *ngIf="status == 'error'">
    We're sorry for the inconvenience. It seems there was an issue processing your request.<br>
    Please reach out to your branch for assistance.<br>
    They'll be able to provide you with more information about the status of your payment</h2>

    <h2 *ngIf="status == 'declined'">
    Please try again, this transaction has been rejected by your bank.<br>
    Your bank is having issues authenticating that it's you - they may send an SMS,<br>
    give you a phone call or send a push notification on your banking app to verify<br>
    your identity.</h2>

    <h2 *ngIf="status == 'timeout'">
    The payment is still being processed by our Felloh partners and its taking longer than expected.<br>
    To find out if the payment was successful, please contact our branch.</h2>
  
    <h3 *ngIf="status == 'timeout'">Transaction ID: <i>{{ paymentId }}</i></h3>

    <h2>Thank you for your patience. </h2>

    <h3>Kind Regards,<br>
    {{branchName}}</h3>
  </div>

</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  async sendReceipt(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json'};
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'emailFellohReceipt', body, {headers}).toPromise();
    return output;
  }

  async getBookingFull(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getBookingFull?publicRequest=' +
    request.publicRequest + '&publicKey=' + request.publicKey).toPromise();
    return output;
  }

  async getItineraryAddons(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getItineraryAddons?publicRequest=' +
    request.publicRequest + '&publicKey=' + request.publicKey).toPromise();
    return output;
  }

  async createItineraryAddon(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json'};
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'createItineraryAddon', body, {headers}).toPromise();
    return output;
  }

  async archiveItineraryAddon(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json'};
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'archiveItineraryAddon', body, {headers}).toPromise();
    return output;
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(private http: HttpClient, private apiService: ApiService) { }

  getQuoteDetails(customerID: string, quoteID: string): Promise<any> {
    // Simulate API call
    return new Promise((resolve, reject) => {
      // Simulated quote data
      const quote = {
        destination: 'Bahamas',
        price: 1200,
        travelDate: new Date(),
        details: 'A beautiful trip to the Bahamas.',
      };

      // Simulate success or failure
      if (customerID && quoteID) {
        resolve(quote);
      } else {
        reject('Invalid parameters');
      }
    });
  }


  async getCustomer(customer: any): Promise<object> {
    let url = environment.apiURL + 'customer?tradeCode=' + customer.tradeCode + '&email=' + customer.email + '&lastName=' +
    encodeURIComponent(customer.lastName) + '&telNo=' + customer.telNo + '&token=' + customer.token;

    if (customer.exclusiveToId) { url += '&exclusiveToId=' + customer.exclusiveToId; }

    return this.apiService.makeHttpRequest('get', url);
  }
}

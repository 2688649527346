import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FellohService } from '../../services/felloh.service';
import { environment } from './../../../environments/environment';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-felloh-success',
  templateUrl: './felloh-success.component.html',
  styleUrls: ['./felloh-success.component.css', '../../customerFace.css'],
  animations: [
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class FellohSuccessComponent implements OnInit {

  pageLoaded = false;
  fakeEmail = false;

  customerName = '';
  email = '';
  amount: any = '';
  currency = '';
  description = '';
  branchLogo = '';
  branchName = '';
  systemMessage = '';

  constructor(private router: Router, private location: Location, private fellohService: FellohService,
              private route: ActivatedRoute) { this.location.replaceState(''); }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.route.queryParams.subscribe(query => {
        if (params.u !== undefined && params.p !== undefined && params.id !== undefined &&
            params.branchName !== undefined && params.branchLogo !== undefined) {

          const request: any = {
            date_from: null, date_to: null, keyword: '',
            'show-child-organisations': true, skip: 0, take: 25
          };

          if (environment.production) { request.organisation = 'T0000'; }
          else { request.organisation = 'KN3Q6M8Y'; }

          let tokenOut = '';

          // Catch any errors whilst decoding..
          try { this.branchName = decodeURI(params.branchName); } catch { }
          try { this.branchLogo = decodeURI(params.branchLogo); } catch { }

          // If any of the below was empty, it would redirect to 'Unknown Request'
          if (this.branchName === '') { this.branchName = 'null'; }
          if (this.branchLogo === '') { this.branchLogo = 'null'; }

          if (query.hasOwnProperty('token')) {

            if (query.hasOwnProperty('id')) { request.keyword = query.id; }
            if (query.hasOwnProperty('paymentId')) { request.keyword = query.paymentId; }
            tokenOut = query.token;

            this.delay(2500).then(() => {
              this.fellohService.getPaymentStatusV2(request, tokenOut).then((txnDetails: any) => {
                // Print out outputStatusId if script is being run in non-production (home env / dev site)
                if (environment.production === false) { console.log(txnDetails); }
                const txn = txnDetails.data[0];
                // Work out whether we can get the actual branch name and logoRef from payment link
                this.branchName = txn?.payment_link?.metadata?.tradingName || this.branchName;
                this.branchLogo = txn?.payment_link?.metadata?.logoURL || this.branchLogo;
                // Check payment's status below
                if (txn.status === 'ABANDONED') {
                  console.log('hello 1?'); // Just to see where it failed..
                  this.router.navigate(['/cancelFelloh/' + this.branchName + '/' + this.branchLogo + '/error']);
                } else if (txn.status === 'PENDING' || txn.status === 'PAYMENTINPROGRESS') {
                  console.log('hello 2?'); // Just to see where it failed..
                  this.router.navigate(['/cancelFelloh/' + this.branchName + '/' + this.branchLogo + '/timeout/' + txn.id]);
                } else if (txn.status === 'DECLINED' || txn.status === 'REJECTED') {
                  console.log('hello 3?'); // Just to see where it failed..
                  this.router.navigate(['/cancelFelloh/' + this.branchName + '/' + this.branchLogo + '/declined']);
                } else if (txn.status === 'COMPLETE') {
                  this.customerName = txn.payment_link.customer_name;
                  this.email = txn.payment_link.email;
                  
                  // Felloh returns amount in 0.01, hence we need to convert these..
                  if (txn.currency === 'GBX') { this.currency = 'GBP'; this.amount = txn.amount / 100; }
                  else if (txn.currency === 'USX') { this.currency = 'USD'; this.amount = txn.amount / 100; }
                  else if (txn.currency === 'EUX') { this.currency = 'EUR'; this.amount = txn.amount / 100; }
                  else { this.amount = txn.amount; this.currency = txn.currency; }

                  this.description = txn.payment_link.description;

                  if (this.email.substring(0, 4) === 'cust' && this.email.includes('@thetravelnetworkgroup.co.uk')) {
                    this.fakeEmail = true;
                  }
                  this.pageLoaded = true;
                }
              }).catch((error: any) => {
                console.log('hello 4?'); // Just to see where it failed..
                this.router.navigate(['/cancelFelloh/' + this.branchName + '/' + this.branchLogo + '/timeout']);
              });
            });
          } else {
            console.log('hello 5?'); // Just to see where it failed..
            this.router.navigate(['/cancelFelloh/' + this.branchName + '/' + this.branchLogo + '/timeout']);
          }
        } else {
          console.log('hello 6?'); // Just to see where it failed..
          this.router.navigate(['/']);
        }
      });
    });
  }

  async delay(ms: number): Promise<void> {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log(''));
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteService } from 'src/app/services/quote.service';
import * as L from 'leaflet';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css'],
})
export class QuoteDetailsComponent implements OnInit {
  customerID: string | null = null;
  quoteID: string | null = null;
  quote: any = null;
  accessDenied = false;

  itineraryItems = [
    {
      day: 'Day 1',
      icon: 'fa-plane',
      title: 'London Heathrow Airport to Orlando International Airport',
      duration: '59 hours',
      airline: 'British Airways',
      cabinClass: 'Premium Economy',
      flightNumber: 'BA2313',
      checkInTime: '3h 0m Before Flight',
      baggageAllowance: '2 pc',
      departure: '07/01/2025 09:00',
      arrival: '09/01/2025 15:00',
    },
    {
      day: 'Day 2',
      icon: 'fa-bed',
      title: 'Hilton Fort Lauderdale Marina',
      location: 'Fort Lauderdale - Hollywood Area - FL',
      checkIn: '07/01/2025 at 15:00',
      checkOut: '09/01/2025 at 12:00',
      nights: '2 Nights',
      board: 'Room Only',
      roomType: 'Double King Size Bed',
      overview:
        'Renew your body and spirit at Hilton Fort Lauderdale Marina poised at the edge of the splendid Intracoastal Waterway...',
    },
  ];


  whatsIncluded = [
    '9 night cruise',
    'Flight to Orlando International Airport',
    '2 nights in Home2 Suites by Hilton Port Arthur',
  ];

  totalPrice = 4600.0;

  panels = [
    { title: 'Overview', content: 'Details about the ship\'s overview...' },
    { title: 'Dining', content: 'Details about dining options...' },
    { title: 'Entertainment', content: 'Details about entertainment...' },
    { title: 'Health and Fitness', content: 'Details about health and fitness...' },
    { title: 'Kids and Teens', content: 'Details about activities for kids and teens...' },
    { title: 'Decks', content: 'Details about the decks...' },
    { title: 'Cabins & Fares', content: 'Details about cabins and fares...' },
  ];


  destinations = [
    {
      image: 'https://cdn.tprofile.com/content/Regions/9995093_florida/GALLERY_93ae7b_florida_1.jpg',
      title: 'Florida',
      description:
        'As well as being the Southernmost point in the USA, Florida has the longest coastline of any state, making it a paradise for travelers from across the globe. From thrill seekers at the most famous area for theme parks in the world, right to the serene beaches...',
      actions: [{ label: 'Ask a Question', type: 'cta' }],
    },
    {
      image: null,
      title: 'Fort Lauderdale',
      description: 'Willemstad is the capital and largest city of the island country of Curaçao, which is part of the Dutch Caribbean...',
      actions: [
        { label: 'Overview', type: 'info' },
        { label: 'Climate', type: 'info' },
      ],
    },
  ];

  feedbackType: string = '';
  comments: string = '';
  

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private quoteService: QuoteService
  ) {}

  ngOnInit(): void {
    // Extract customerID and quoteID from the URL
    this.customerID = this.route.snapshot.paramMap.get('customerID');
    this.quoteID = this.route.snapshot.paramMap.get('quoteID');

    // Fetch quote details
    if (this.customerID && this.quoteID) {
      this.quoteService
        .getQuoteDetails(this.customerID, this.quoteID)
        .then((quote: any) => {
          this.quote = quote;
        })
        .catch((error: any) => {
          console.error('Failed to fetch quote details:', error);
          this.accessDenied = true;
        });
    } else {
      this.accessDenied = true;
    }
  }

  ngAfterViewInit(): void {
    const map = L.map('map').setView([26.1224, -80.1373], 10); // Center at Fort Lauderdale
  
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);
  
    setTimeout(() => {
      map.invalidateSize(); // Ensure map resizes correctly
    }, 100);
  }

  editQuote(): void {
    console.log('Edit functionality here');
  }

  deleteQuote(): void {
    console.log('Delete functionality here');
  }

  submitFeedback() {
    if (this.feedbackType) {
      alert(`Feedback submitted: ${this.feedbackType}\nComments: ${this.comments}`);
    } else {
      alert('Please select a feedback option.');
    }
  }
}

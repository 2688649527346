<div style="height: 100vh" *ngIf="!pageLoaded">
  <div class="loading-indicator">
    <i class="fa-solid fa-plane-up fa-beat-fade"></i>
  </div>
</div>

<mat-card-content>
  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" closed></mat-sidenav>
    <mat-sidenav-content>
      <mat-card>
        <div class="mainDiv">

          <div class="bookingReferenceHeading">
            <h1>{{ bookingData.bookingReference }}</h1>
          </div>

          <div class="dpeartureCountdown">
            <h2 style="margin-bottom: 0px;">Departure countdown
              <br>{{ daysToDeptDate }}
              <span *ngIf="daysToDeptDate == 1">day</span>
              <span *ngIf="daysToDeptDate != 1">days</span>
            </h2>

            <div style="float: left;">
              <svg-icon src="assets/icons/calendar.svg"
                [svgStyle]="{'width.px':30,'height.px':30,'fill':'#4D5FD1','padding.px':1,'margin.px':1,'margin-top.px':5}">
              </svg-icon>
            </div>
            <mat-progress-bar mode="determinate" color="primary" value="{{progressBarValue}}"
              style="width: calc(100% - 80px); margin: auto; margin-top: 20px; float: left;"></mat-progress-bar>
            <div>
              <svg-icon src="assets/icons/plane-taking-off.svg"
                [svgStyle]="{'width.px':30,'height.px':30,'fill':'#4D5FD1','padding.px':1,'margin.px':1,'margin-top.px':5}">
              </svg-icon>
            </div>
          </div>

          <div class="containerBooking">
            <div>
              <div class="generalInfoRow">
                <div class="infoRowWrapper">
                  <div class="infoRow"><b>Booking Reference</b></div>
                  <div id="bookingReference" style="margin: auto;">{{bookingData.bookingReference | uppercase}}</div>
                </div>
                <div class="infoRowWrapper">
                  <div class="infoRow"><b>Branch Name</b></div>
                  <div id="branchName" style="margin: auto;">{{bookingData.branchName}}</div>
                </div>
                <div class="infoRowWrapper">
                  <div class="infoRow"><b>Travel Agent</b></div>
                  <div id="agentEmail" style="margin: auto;">{{bookingData.agentEmail}}</div>
                </div>
                <div class="infoRowWrapper">
                  <div class="infoRow"><b>Status</b></div>
                  <div id="bookingStatus" style="margin: auto;">{{bookingData.bookingStatus | titlecase}}</div>
                </div>
              </div>

              <div class="generalInfoRow" style="margin-top: 25px;">
                <div>
                  <div style="font-size: 22px;">Itinerary</div>
                  <div>
                    <button class="addRemoveButtons" mat-stroked-button color="primary" type="button"
                      (click)="goToItinerary()">
                      <mat-icon>event_note</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

            </div>

            <div>
              <div class="generalInfoRow">
                <div class="infoRowWrapper">
                  <div class="infoRow"><b matTooltip="Auto-picks earliest Booking Date from all elements">Booking
                      Date</b></div>
                  <div id="bookingDate" style="margin: auto;">
                    <span class="editSpans">{{bookingData.bookingDate | date:'dd.MM.yyyy'}}</span>
                  </div>
                </div>
                <div class="infoRowWrapper">
                  <div class="infoRow"><b matTooltip="Auto-picks earliest Departure/Check-In Date from all elements">Due Date</b></div>
                  <div id="balanceDueDate" style="margin: auto;">
                    <span class="editSpans">{{bookingData.balanceDueDate | date:'dd.MM.yyyy'}}</span>
                  </div>
                </div>
                <div class="infoRowWrapper">
                  <div class="infoRow"><b matTooltip="Auto-picks earliest Departure/Check-In Date from all elements">Departure Date</b>
                  </div>
                  <div id="deptDate" style="margin: auto;">
                    <span class="editSpans">{{bookingData.deptDate | date:'dd.MM.yyyy'}}</span>
                  </div>
                </div>
                <div class="infoRowWrapper">
                  <div class="infoRow"><b matTooltip="Auto-picks latest Return Date from all elements">Return Date</b></div>
                  <div id="returnDate" style="margin: auto;">
                    <span class="editSpans">{{bookingData.returnDate | date:'dd.MM.yyyy'}}</span>
                  </div>
                </div>
              </div>

              <div class="generalInfoRow" style="margin-top: 25px; visibility: hidden;">
                <div>
                  <div style="font-size: 22px;">Tba</div>
                  <div>
                    <button class="addRemoveButtons" mat-stroked-button color="primary" type="button">
                      <mat-icon>description</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="fellohLinks">
            <div style="font-size: 22px;">Active Payment Links</div>
            <div style="font-size: 12px;"><i>Below is a list of payment links created by the merchant. Click on a link to proceed to the payment portal and make a payment.</i></div>

            <mat-table multiTemplateDataRows [dataSource]="fellohLinks">
              <!-- Columns -->
              <ng-container matColumnDef="branchName">
                <mat-header-cell *matHeaderCellDef class="left-align">Merchant Name</mat-header-cell>
                <mat-cell *matCellDef="let link" class="left-align"> <b>{{ link.organisation.name }}</b> </mat-cell>
              </ng-container>

              <ng-container matColumnDef="custName">
                <mat-header-cell *matHeaderCellDef class="center-align">Payer</mat-header-cell>
                <mat-cell *matCellDef="let link" class="center-align"> <b>{{ link.customer_name }}</b> </mat-cell>
              </ng-container>

              <ng-container matColumnDef="expiryDate">
                <mat-header-cell *matHeaderCellDef class="center-align">Expiration Date</mat-header-cell>
                <mat-cell *matCellDef="let link" class="center-align"> <b>{{ link.expires_at | date: "dd.MM.y hh:mm a" }}</b> </mat-cell>
              </ng-container>

              <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef class="right-align">Amount</mat-header-cell>
                <mat-cell *matCellDef="let link" class="right-align"> <b>{{ link.amount | currency:link.currency  }}</b> </mat-cell>
              </ng-container>
            
              <!-- Table Rows -->
              <mat-header-row *matHeaderRowDef="fellohLinksColumns;"></mat-header-row>
              <mat-row *matRowDef="let row; columns : fellohLinksColumns;" (click)="openFellohLink(row.url)"></mat-row>
            </mat-table>
          </div>

          <div class="charts">
            <div class="wrapper">
              <div class="pieDiv">
                <div id="travelTimeline" style="text-align: center; margin-bottom: 50px;">
                  <h1>Your Payments</h1>
                </div>
                <apx-chart style="text-align: center;" [series]="pieChartOptions1.series"
                  [chart]="pieChartOptions1.chart" [labels]="pieChartOptions1.labels" [colors]="pieChartOptions1.colors"
                  [stroke]="pieChartOptions1.stroke" [yaxis]="pieChartOptions1.yaxis"
                  [plotOptions]="pieChartOptions1.plotOptions" [legend]="pieChartOptions1.legend"
                  [dataLabels]="pieChartOptions1.dataLabels"></apx-chart>
              </div>

              <div class="timeLineDiv">
                <div id="travelTimeline" style="text-align: center;">
                  <h1>Travel Timeline</h1>
                </div>
                <apx-chart #timeLineChart id="chart" [series]="chartOptions.series" [chart]="chartOptions.chart"
                  [grid]="chartOptions.grid" [plotOptions]="chartOptions.plotOptions" [tooltip]="chartOptions.tooltip"
                  [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis" [legend]="chartOptions.legend"
                  [colors]="chartOptions.colors">
                </apx-chart>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-card-content>

<ng-template #chartDialog>
  <mat-dialog-content style="min-width: 350px;">
    <div>
      <!--<div>
        <h1 style="margin-top: 0px; display: inline; font-weight: 700;">{{ chartSelected.costing.supplierName }}</h1>
        <h3 style="margin-top: 0px; font-weight: 700;">
          <i>
            <span>{{ chartSelected.costing.supplierReference }}</span>
          </i>
        </h3>
      </div>-->

      <h2 style="margin-top: 0px;">{{ chartSelected.niceType }} Details</h2>
      <mat-divider></mat-divider>

      <div style="font-size: 15px;">
        <div *ngIf="chartSelected.type === 'flight'">

          <div style="clear: both;">
            <mat-divider class="divider"></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Departure</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.departAirCode }} {{ chartSelected.element.departAirName }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;"></div>
                  <div fxFlex="60" style="margin: auto;"><span
                      class="dialogCostInfo">{{chartSelected.element.departDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0"
                      }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Arrival</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.destinAirCode }} {{ chartSelected.element.arriveAirName }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;"></div>
                  <div fxFlex="60" style="margin: auto;"><span
                      class="dialogCostInfo">{{chartSelected.element.arriveDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0"
                      }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Carrier</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.carrier |
                      titlecase }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Direction</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.direction
                      | titlecase }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Class</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.flightClass }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Status</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.flightStatus | titlecase }}</span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'accommodation'">

          <div style="clear: both;">
            <mat-divider class="divider"></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Check-In Date</div>
                  <div fxFlex="70" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.checkInDate | date:'dd.MM.yyyy'}}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Nights</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.numNights
                      }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Name</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.accomName
                      }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Address</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.address
                      }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">City</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.city
                      }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Country</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.country
                      }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Rating</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.rating
                      }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Status</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.accomStatus | titlecase }}</span></div>
                </div>

                <div style="width: 100%;">
                  <h2 style="float: left;">Room {{ addnDataView + 1 }} Details</h2>
                  <span style="float: right; margin-top: 2.5px;">
                    <button [disabled]="addnDataView <= 0" class="addRemoveButtons" mat-stroked-button color="primary"
                      (click)="addnDataView = addnDataView - 1" type="button">
                      <mat-icon>navigate_before</mat-icon>
                    </button>
                    <button [disabled]="addnDataView >= chartSelected.element.addndata.length - 1"
                      class="addRemoveButtons" mat-stroked-button color="primary"
                      (click)="addnDataView = addnDataView + 1" type="button">
                      <mat-icon>navigate_next</mat-icon>
                    </button>
                  </span>
                </div>

                <mat-divider style="clear: both;"></mat-divider>
                <br>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Room Types</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.addndata[addnDataView]?.valueStr1 }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Room Views</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.addndata[addnDataView]?.valueStr2 }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Board Basis</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.addndata[addnDataView]?.valueStr3 }}</span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'carHire'">

          <div style="clear: both;">
            <mat-divider class="divider"></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Pick Up Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.pickUpDate | date:'dd.MM.yyyy'}}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Location</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.pickUpLocation }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Drop-Off Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.dropOffDate | date:'dd.MM.yyyy'}}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Location</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.dropOffLocation }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Car Type</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.carType
                      }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Child Seats</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.childseats }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Status</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.carHireStatus | titlecase }}</span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'carParking'">

          <div style="clear: both;">
            <mat-divider class="divider"></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Parking Starts</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.startDate
                      | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Parking Ends</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.endDate |
                      date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Location</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.location
                      }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Parking Name</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.carParkName }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Parking Code</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.carParkCode }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Car Make</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.carMake
                      }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Car Model</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.carModel
                      }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Registration</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.carReg
                      }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Status</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.carparkStatus | titlecase }}</span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'attraction'">

          <div style="clear: both;">
            <mat-divider class="divider"></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Start Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.startDateTime
                      | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">End Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.endDateTime
                      | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Attraction Name</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.name
                      }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Location</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.location
                      }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Country</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.country
                      }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Status</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.attractionStatus | titlecase }}</span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'cruise'">

          <div style="clear: both;">
            <mat-divider class="divider"></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Departure Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.deptDate
                      | date:'dd.MM.yyyy'}}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Duration</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.duration
                      }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Cruise Name & Code</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.cruiseName }} <span *ngIf="chartSelected.element.voyageCode != ''">({{
                        chartSelected.element.voyageCode }})</span></span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Cruise Line & Ship</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.cruiseLine }} <span *ngIf="chartSelected.element.cruiseShip != ''">({{
                        chartSelected.element.cruiseShip }})</span></span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Departs Port</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.departurePort }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Visiting Port</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.visitingPort }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Disembark Port</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.disembarkPort }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Region</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.region
                      }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">On Board Credit</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">
                  <span *ngIf="chartSelected.element.onBoardCreditCur !== ''">
                    {{ chartSelected.element.onBoardCreditVal | currency: chartSelected.element.onBoardCreditCur }}
                  </span>
                  <span *ngIf="chartSelected.element.onBoardCreditCur === ''">
                    {{ chartSelected.element.onBoardCreditVal }}
                  </span>
                  
                  </span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Status</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.cruiseStatus | titlecase }}</span></div>
                </div>

                <div style="width: 100%;">
                  <h2 style="float: left;">Cabin {{ addnDataView + 1 }} Details</h2>
                  <span style="float: right; margin-top: 2.5px;">
                    <button [disabled]="addnDataView <= 0" class="addRemoveButtons" mat-stroked-button color="primary"
                      (click)="addnDataView = addnDataView - 1" type="button">
                      <mat-icon>navigate_before</mat-icon>
                    </button>
                    <button [disabled]="addnDataView >= chartSelected.element.addndata.length - 1"
                      class="addRemoveButtons" mat-stroked-button color="primary"
                      (click)="addnDataView = addnDataView + 1" type="button">
                      <mat-icon>navigate_next</mat-icon>
                    </button>
                  </span>
                </div>

                <mat-divider style="clear: both;"></mat-divider>
                <br>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Name & Number</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.addndata[addnDataView]?.valueStr1 }} <span
                        *ngIf="chartSelected.element.addndata[addnDataView]?.valueStr3 != ''">({{
                        chartSelected.element.addndata[addnDataView]?.valueStr3 }})</span></span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Deck & Grade</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.addndata[addnDataView]?.valueStr4 }} <span
                        *ngIf="chartSelected.element.addndata[addnDataView]?.valueStr2 != ''">({{
                        chartSelected.element.addndata[addnDataView]?.valueStr2 }})</span></span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Cabin Position</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.addndata[addnDataView]?.valueStr6 }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Ship Side</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.addndata[addnDataView]?.valueStr5 }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Bed Type</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.addndata[addnDataView]?.valueStr7 }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Seating</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.addndata[addnDataView]?.valueStr8 }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Table Size</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.addndata[addnDataView]?.valueStr9 }}</span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'miscellaneous'">

          <div style="clear: both;">
            <mat-divider class="divider"></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Start Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.startDateTime
                      | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">End Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.endDateTime
                      | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Misc Name</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.name
                      }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Location</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.location
                      }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Country</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.country
                      }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Status</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.miscStatus | titlecase }}</span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'train'">

          <div style="clear: both;">
            <mat-divider></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Departure</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.departStation }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;"></div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{chartSelected.element.departDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Arrival</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.arriveStation }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;"></div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{chartSelected.element.arriveDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Train Name / Number</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.trainNameNo }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Carriage Class</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.carriageClass }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Seat Number</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.seat }}</span></div>
                </div>

              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'transfer'">

          <div style="clear: both;">
            <mat-divider class="divider"></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Pick Up Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.pickUpDateTime | date:'dd.MM.yyyy hh:mm a':'GMT+0' }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Instructions</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.pickUpInstructions }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Drop Off Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.dropOffDateTime | date:'dd.MM.yyyy hh:mm a':'GMT+0' }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Instructions</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.dropOffInstructions }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Vehicle Type</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.VehicleType }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Route</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.pickUpLocation }} <mat-icon style="margin-top: -2px;">east</mat-icon> {{
                      chartSelected.element.dropOffLocation }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Country</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.country
                      }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Emergency No.</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.emergencyResortContact }} <span
                        *ngIf="chartSelected.element.emergencyPhoneNo != ''">({{chartSelected.element.emergencyPhoneNo}})</span></span>
                  </div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Status</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{
                      chartSelected.element.transferStatus | titlecase }}</span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-around end"
        *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
        <div style="margin-bottom: 0px;">
          <button style="margin-bottom: 0px;" type="button" mat-raised-button color="accent"
            (click)="addElement(chartSelected.costing)">Add Element</button>
        </div>

        <div style="margin-bottom: 0px;">
          <button style="margin-bottom: 0px;" type="button" mat-raised-button color="accent"
            (click)="editElement(chartSelected.type, chartSelected.costing)">Edit Element</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>
<div class="container-fluid itinerary-container">
    <!-- Back button -->
    <h5 class="topButtons">
        <div style="float: left; text-align: center;">
            <p style="margin: 0px;">Back</p>
            <button class="addRemoveButtons" mat-stroked-button color="primary" type="button" (click)="goBackToBooking()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div style="float: right; text-align: center;">
            <p style="margin: 0px;">Print</p>
            <button class="addRemoveButtons" mat-stroked-button color="primary" type="button" onclick="window.print();">
                <mat-icon>print</mat-icon>
            </button>
        </div>
    </h5>
    <!-- Header -->
    <header style="clear: both;">
        <div class="row align-items-center">
            <div class="col-sm-7 text-center text-sm-left mb-3 mb-sm-0">
                <img id="logo" src="https://sings-media.s3.eu-west-2.amazonaws.com/logo/{{logoUrl}}"
                    style="width: 50%; mix-blend-mode: multiply;">
            </div>
            <div class="col-sm-5 text-center text-sm-right">
                <h4 class="text-7 mb-0">Travel Itinerary</h4>
            </div>
        </div>
        <hr class="my-4">
    </header>
    <!-- Header End -->

    <!-- Main Content -->
    <main *ngIf="branchData.itineraryOption == 'successive'">
        <div class="custColumn">
            <div class="row">
                <div class="col-sm-4"> <strong class="font-weight-600">Full Name:</strong>
                    <p>{{ leadPaxData.firstName }} {{ leadPaxData.middleName }} {{ leadPaxData.lastName }}</p>
                    <strong class="font-weight-600">Email:</strong>
                    <p>{{ leadPaxData.email }}</p>
                </div>
                <div class="col-sm-4"> <strong class="font-weight-600">Booking Reference:</strong>
                    <p>{{ bookingData.bookingReference }}</p>
                    <strong class="font-weight-600">Agent Email:</strong>
                    <p>{{ bookingData.agentEmail }}</p>
                </div>
                <div class="col-sm-4"> <strong class="font-weight-600">Address:</strong>
                    <address>
                        <span *ngFor="let addressLn of customerAddress; let last = last;">
                            {{ addressLn }}<span *ngIf="!last">, <br></span>
                        </span>
                    </address>
                </div>
            </div>
        </div>

        <div class="custColumn">
            <div class="card mt-4" *ngIf="elementSorted.attractions.length > 0">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>attractions</mat-icon>
                        </span>Attractions
                    </h5>
                </div>
                <div class="card-body">
                    <span *ngFor="let attraction of elementSorted.attractions; let last = last; let index = index;">
                        <div class="row mt-n3" [ngClass]="{'border-bottom': !last}">
                            <div class="col-sm-4 mt-3"> <b>Name:</b>
                                <p class="font-weight-600 mb-0">{{ attraction.name }}</p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Start Date:</b>
                                <p class="font-weight-600 mb-0">{{ attraction.startDateTime | date:"dd.MM.yyyy, hh:mm a":"GMT+0" }}</p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>End Date:</b>
                                <p class="font-weight-600 mb-0">{{ attraction.endDateTime | date:"dd.MM.yyyy, hh:mm a":"GMT+0" }}</p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Location:</b>
                                <p class="font-weight-600 mb-0">
                                    <span *ngIf="attraction.location != ''">{{ attraction.location }}, </span>
                                    <span *ngIf="attraction.country != ''">{{ attraction.country }}</span>
                                </p>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>

        <div class="custColumn">
            <div class="card mt-4" *ngIf="elementSorted.carHires.length > 0">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>drive_eta</mat-icon>
                        </span>Car Hires
                    </h5>
                </div>
                <div class="card-body">
                    <span *ngFor="let carHire of elementSorted.carHires; let last = last; let index = index;">
                        <div class="row mt-n3" [ngClass]="{'border-bottom': !last}">
                            <div class="col-sm-4 mt-3">
                                <span *ngIf="carHire.carType && carHire.carType != ''">
                                    <b>Car Type:</b>
                                    <p class="font-weight-600 mb-0">{{ carHire.carType }}</p>
                                </span>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Pick Up:</b>
                                <p class="font-weight-600 mb-0">{{ carHire.pickUpDate | date:"dd.MM.yyyy" }}</p>
                                <p class="font-weight-600 mb-0">{{ carHire.pickUpLocation }}</p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Drop Off:</b>
                                <p class="font-weight-600 mb-0">{{ carHire.dropOffDate | date:"dd.MM.yyyy" }}</p>
                                <p class="font-weight-600 mb-0">{{ carHire.dropOffLocation }}</p>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>

        <div class="custColumn">
            <div class="card mt-4" *ngIf="elementSorted.carParks.length > 0">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>local_parking</mat-icon>
                        </span>Car Parks
                    </h5>
                </div>
                <div class="card-body">
                    <span *ngFor="let carPark of elementSorted.carParks; let last = last; let index = index;">
                        <div class="row mt-n3" [ngClass]="{'border-bottom': !last}">
                            <div class="col-sm-4 mt-3">
                                <div *ngIf="(carPark.location && carPark.location != '') || (carPark.carParkName && carPark.carParkName != '') || (carPark.carParkCode && carPark.carParkCode != '')"
                                    style="margin-bottom: 10px;">
                                    <b>Car Park Details:</b>
                                    <p class="font-weight-600 mb-0">{{ carPark.location }}</p>
                                    <p class="font-weight-600 mb-0">{{ carPark.carParkName }}</p>
                                    <p class="font-weight-600 mb-0">{{ carPark.carParkCode }}</p>
                                </div>
                                <div *ngIf="(carPark.carMake && carPark.carMake != '') || (carPark.carModel && carPark.carModel != '') || (carPark.carReg && carPark.carReg != '')">
                                    <b>Car Details:</b>
                                    <p class="font-weight-600 mb-0">{{ carPark.carMake }}</p>
                                    <p class="font-weight-600 mb-0">{{ carPark.carModel }}</p>
                                    <p class="font-weight-600 mb-0">{{ carPark.carReg }}</p>
                                </div>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Start Date:</b>
                                <p class="font-weight-600 mb-0">{{ carPark.startDate | date:"dd.MM.yyyy, hh:mm a"
                                    :"GMT+0" }}</p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>End Date:</b>
                                <p class="font-weight-600 mb-0">{{ carPark.endDate | date:"dd.MM.yyyy, hh:mm a" :"GMT+0"
                                    }}</p>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>

        <div class="custColumn">
            <div class="card mt-4" *ngIf="elementSorted.cruises.length > 0">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>directions_boat</mat-icon>
                        </span>Cruises
                    </h5>
                </div>
                <div class="card-body">
                    <span *ngFor="let cruise of elementSorted.cruises; let last = last; let index = index;">
                        <div class="row mt-n3" [ngClass]="{'border-bottom': !last}">
                            <div class="col-sm-4 mt-3"> <b>Cruise Details:</b>
                                <p class="font-weight-600 mb-0">{{ cruise.cruiseName }}</p>
                                <p class="font-weight-600 mb-0">{{ cruise.cruiseLine }}</p>
                                <p class="font-weight-600 mb-0">{{ cruise.cruiseShip }}</p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Departure:</b>
                                <p class="font-weight-600 mb-0">{{ cruise.deptDate | date:"dd.MM.yyyy" }}</p>
                                <p class="font-weight-600 mb-0">{{ cruise.departurePort }}</p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Return:</b>
                                <p class="font-weight-600 mb-0">{{ cruise.returnDate | date:"dd.MM.yyyy" }}</p>
                                <p class="font-weight-600 mb-0">{{ cruise.disembarkPort }}</p>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>

        <div class="custColumn">
            <div class="card mt-4" *ngIf="elementSorted.flights.length > 0">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>flight</mat-icon>
                        </span>Flights
                    </h5>
                </div>
                <div class="card-body">
                    <span *ngFor="let flight of elementSorted.flights; let last = last; let index = index;">
                        <div class="row mt-n3" [ngClass]="{'border-bottom': !last}">
                            <div class="col-sm-4 mt-3"> <b>Flight Details:</b>
                                <p class="font-weight-600 mb-0">{{ flight.carrier }}</p>
                                <p class="font-weight-600 mb-0">{{ flight.number }}</p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Departure:</b>
                                <p class="font-weight-600 mb-0">{{ flight.departDateTime | date:"dd.MM.yyyy, hh:mm a"
                                    :"GMT+0" }}</p>
                                <p class="font-weight-600 mb-0">{{ flight.departAirName }}
                                    <span *ngIf="flight.departAirCode != ''">({{ flight.departAirCode }})</span>
                                </p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Arrival:</b>
                                <p class="font-weight-600 mb-0">{{ flight.arriveDateTime | date:"dd.MM.yyyy, hh:mm a"
                                    :"GMT+0" }}</p>
                                <p class="font-weight-600 mb-0">{{ flight.arriveAirName }}
                                    <span *ngIf="flight.destinAirCode != ''">({{ flight.destinAirCode }})</span>
                                </p>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>

        <div class="custColumn">
            <div class="card mt-4" *ngIf="elementSorted.hotels.length > 0">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>hotel</mat-icon>
                        </span>Accommodations
                    </h5>
                </div>
                <div class="card-body">
                    <span *ngFor="let hotel of elementSorted.hotels; let last = last; let index = index;">
                        <div class="row mt-n3" [ngClass]="{'border-bottom': !last}">
                            <div class="col-sm-4 mt-3"> <b>Accommodation:</b>
                                <p class="font-weight-600 mb-0">{{ hotel.accomName }}</p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Duration:</b>
                                <p class="font-weight-600 mb-0">{{ hotel.checkInDate | date:"dd.MM.yyyy" }}</p>
                                <p class="font-weight-600 mb-0">{{ hotel.numNights }} night(s)</p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Address:</b>
                                <p class="font-weight-600 mb-0" *ngIf="hotel.address != ''">{{ hotel.address }}</p>
                                <p class="font-weight-600 mb-0">{{ hotel.city }}</p>
                                <p class="font-weight-600 mb-0">{{ hotel.country }}</p>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>

        <div class="custColumn">
            <div class="card mt-4" *ngIf="elementSorted.miscs.length > 0">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>miscellaneous_services</mat-icon>
                        </span>Miscellaneous
                    </h5>
                </div>
                <div class="card-body">
                    <span *ngFor="let misc of elementSorted.miscs; let last = last; let index = index;">
                        <div class="row mt-n3" [ngClass]="{'border-bottom': !last}">
                            <div class="col-sm-4 mt-3"> <b>Name:</b>
                                <p class="font-weight-600 mb-0">{{ misc.name }}</p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Start Date:</b>
                                <p class="font-weight-600 mb-0">{{ misc.startDateTime | date:"dd.MM.yyyy, hh:mm a":"GMT+0" }}</p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>End Date:</b>
                                <p class="font-weight-600 mb-0">{{ misc.endDateTime | date:"dd.MM.yyyy, hh:mm a":"GMT+0" }}</p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Location:</b>
                                <p class="font-weight-600 mb-0">
                                    <span *ngIf="misc.location != ''">{{ misc.location }}, </span>
                                    <span *ngIf="misc.country != ''">{{ misc.country }}</span>
                                </p>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>

        <div class="custColumn">
            <div class="card mt-4" *ngIf="elementSorted.transfers.length > 0">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>airport_shuttle</mat-icon>
                        </span>Transfers
                    </h5>
                </div>
                <div class="card-body">
                    <span *ngFor="let transfer of elementSorted.transfers; let last = last; let index = index;">
                        <div class="row mt-n3" [ngClass]="{'border-bottom': !last}">
                            <div class="col-sm-4 mt-3">
                                <div *ngIf="transfer.VehicleType && transfer.VehicleType != ''" style="margin-bottom: 10px;">
                                    <b>Vehicle:</b>
                                <p class="font-weight-600 mb-0">{{ transfer.VehicleType }}</p>
                                </div>
                                <div *ngIf="(transfer.emergencyResortContact && transfer.emergencyResortContact != '') || (transfer.emergencyPhoneNo && transfer.emergencyPhoneNo != '')">
                                    <b>Emergency Contact:</b>
                                    <p class="font-weight-600 mb-0">{{ transfer.emergencyResortContact }}</p>
                                    <p class="font-weight-600 mb-0">{{ transfer.emergencyPhoneNo }}</p>
                                </div>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Pick Up:</b>
                                <p class="font-weight-600 mb-0">{{ transfer.pickUpDateTime | date:"dd.MM.yyyy, hh:mm a"
                                    :"GMT+0" }}</p>
                                <p class="font-weight-600 mb-0">{{ transfer.pickUpLocation }}</p>
                                <p class="font-weight-600 mb-0">{{ transfer.pickUpInstructions }}</p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Drop Off:</b>
                                <p class="font-weight-600 mb-0">{{ transfer.dropOffDateTime | date:"dd.MM.yyyy, hh:mm a"
                                    :"GMT+0" }}</p>
                                <p class="font-weight-600 mb-0">{{ transfer.dropOffLocation }}</p>
                                <p class="font-weight-600 mb-0">{{ transfer.dropOffInstructions }}</p>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>

        <div class="custColumn">
            <div class="card mt-4" *ngIf="elementSorted.trains.length > 0">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>train</mat-icon>
                        </span>Trains
                    </h5>
                </div>
                <div class="card-body">
                    <span *ngFor="let train of elementSorted.trains; let last = last; let index = index;">
                        <div class="row mt-n3" [ngClass]="{'border-bottom': !last}">
                            <div class="col-sm-4 mt-3">
                                <span *ngIf="(train.trainNameNo && train.trainNameNo != '') || (train.carriageClass && train.carriageClass != '') || (train.seat && train.seat != '')">
                                    <b>Train Details:</b>
                                    <p class="font-weight-600 mb-0">{{ train.trainNameNo }}</p>
                                    <p class="font-weight-600 mb-0" *ngIf="train.carriageClass && train.carriageClass != ''">Class: {{ train.carriageClass }}</p>
                                    <p class="font-weight-600 mb-0" *ngIf="train.seat && train.seat != ''">Seat: {{ train.seat }}</p>
                                </span>
                            </div>
    
                            <div class="col-sm-4 mt-3"> <b>Departure:</b>
                                <p class="font-weight-600 mb-0">{{ train.departDateTime | date:"dd.MM.yyyy, hh:mm a"
                                    :"GMT+0" }}</p>
                                <p class="font-weight-600 mb-0">{{ train.departStation }}</p>
                            </div>
                            <div class="col-sm-4 mt-3"> <b>Arrival:</b>
                                <p class="font-weight-600 mb-0">{{ train.arriveDateTime | date:"dd.MM.yyyy, hh:mm a"
                                    :"GMT+0" }}</p>
                                <p class="font-weight-600 mb-0">{{ train.arriveStation }}</p>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>

        <div class="card mt-4" *ngIf="itineraryAddons.length > 0" style="order: 10;">
            <div class="card-header">
                <h5 class="m-0" style="display: flex;">
                    <span class="text-muted mr-2">
                        <mat-icon>extension</mat-icon>
                    </span>Itinerary Extras
                </h5>
            </div>

            <div class="card-body">
                <span *ngFor="let element of itineraryAddons; let last = last; let index = index;">
                <div class="row mt-n3" [ngClass]="{'border-bottom': !last}">
                    <div class="col-sm-4 mt-3">
                        <h6><u>{{element.title}}</u></h6>
                    </div>

                    <div class="col-sm-4 mt-3">
                        <span *ngIf="element.endDateTime && element.endDateTime != ''">
                            <b>Start Date:</b>
                            <p class="font-weight-600 mb-0">{{ element.startDateTime | date:"dd.MM.yyyy, hh:mm a":"GMT+0" }}</p>
                        </span>
                    </div>
                    <div class="col-sm-4 mt-3">
                        <span *ngIf="element.endDateTime && element.endDateTime != ''">
                            <b>End Date:</b>
                            <p class="font-weight-600 mb-0">{{ element.endDateTime | date:"dd.MM.yyyy, hh:mm a":"GMT+0" }}</p>
                        </span>
                    </div>

                    <div style="width: 100%; margin: 15px;">
                        <b>Description:</b>
                        <div style="white-space: pre-line;">{{ element.description }}</div>
                    </div>

                    <div style="display: flex; align-items: center; justify-content: flex-end; width: 100%;">
                        <div class="font-weight-600 mb-0" style="margin-right: 20px;">
                            <button mat-button color="warn" class="removeButton" (click)="archiveItineraryAddon(element)"><mat-icon>delete</mat-icon></button>
                        </div>
                    </div>
                </div>
            </span>
            </div>
        </div>

        <button mat-button (click)="openItineraryDialog()" class="addButtons" style="order: 11; margin-bottom: 0px !important;">
            <mat-icon>add</mat-icon>
        </button>
    </main>

    <main *ngIf="branchData.itineraryOption == 'chronologically'">
        <div class="custColumn">
            <div class="row">
                <div class="col-sm-4"> <strong class="font-weight-600">Full Name:</strong>
                    <p>{{ leadPaxData.firstName }} {{ leadPaxData.middleName }} {{ leadPaxData.lastName }}</p>
                    <strong class="font-weight-600">Email:</strong>
                    <p>{{ leadPaxData.email }}</p>
                </div>
                <div class="col-sm-4"> <strong class="font-weight-600">Booking Reference:</strong>
                    <p>{{ bookingData.bookingReference }}</p>
                    <strong class="font-weight-600">Agent Email:</strong>
                    <p>{{ bookingData.agentEmail }}</p>
                </div>
                <div class="col-sm-4"> <strong class="font-weight-600">Address:</strong>
                    <address>
                        <span *ngFor="let addressLn of customerAddress; let last = last;">
                            {{ addressLn }}<span *ngIf="!last">, <br></span>
                        </span>
                    </address>
                </div>
            </div>
        </div>

        <div class="custColumn" *ngFor="let element of chronologicalOrder">
            <div class="card mt-4" *ngIf="element.attractionStatus">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>attractions</mat-icon>
                        </span>Attraction
                    </h5>
                </div>
                <div class="card-body">
                    <div class="row mt-n3">
                        <div class="col-sm-4 mt-3"> <b>Name:</b>
                            <p class="font-weight-600 mb-0">{{ element.name }}</p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Start Date:</b>
                            <p class="font-weight-600 mb-0">{{ element.startDateTime | date:"dd.MM.yyyy, hh:mm a":"GMT+0" }}</p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>End Date:</b>
                            <p class="font-weight-600 mb-0">{{ element.endDateTime | date:"dd.MM.yyyy, hh:mm a":"GMT+0" }}</p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Location:</b>
                            <p class="font-weight-600 mb-0">
                                <span *ngIf="element.location != ''">{{ element.location }}, </span>
                                <span *ngIf="element.country != ''">{{ element.country }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="element.carHireStatus">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>drive_eta</mat-icon>
                        </span>Car Hire
                    </h5>
                </div>
                <div class="card-body">
                    <div class="row mt-n3">
                        <div class="col-sm-4 mt-3">
                            <span *ngIf="element.carType && element.carType != ''">
                                <b>Car Type:</b>
                                <p class="font-weight-600 mb-0">{{ element.carType }}</p>
                            </span>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Pick Up:</b>
                            <p class="font-weight-600 mb-0">{{ element.pickUpDate | date:"dd.MM.yyyy" }}</p>
                            <p class="font-weight-600 mb-0">{{ element.pickUpLocation }}</p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Drop Off:</b>
                            <p class="font-weight-600 mb-0">{{ element.dropOffDate | date:"dd.MM.yyyy" }}</p>
                            <p class="font-weight-600 mb-0">{{ element.dropOffLocation }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="element.carparkStatus">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>local_parking</mat-icon>
                        </span>Car Park
                    </h5>
                </div>
                <div class="card-body">
                    <div class="row mt-n3">
                        <div class="col-sm-4 mt-3">
                            <div *ngIf="(element.location && element.location != '') || (element.carParkName && element.carParkName != '') || (element.carParkCode && element.carParkCode != '')"
                                style="margin-bottom: 10px;">
                                <b>Car Park Details:</b>
                                <p class="font-weight-600 mb-0">{{ element.location }}</p>
                                <p class="font-weight-600 mb-0">{{ element.carParkName }}</p>
                                <p class="font-weight-600 mb-0">{{ element.carParkCode }}</p>
                            </div>
                            <div *ngIf="(element.carMake && element.carMake != '') || (element.carModel && element.carModel != '') || (element.carReg && element.carReg != '')">
                                <b>Car Details:</b>
                                <p class="font-weight-600 mb-0">{{ element.carMake }}</p>
                                <p class="font-weight-600 mb-0">{{ element.carModel }}</p>
                                <p class="font-weight-600 mb-0">{{ element.carReg }}</p>
                            </div>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Start Date:</b>
                            <p class="font-weight-600 mb-0">{{ element.startDate | date:"dd.MM.yyyy, hh:mm a"
                                :"GMT+0" }}</p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>End Date:</b>
                            <p class="font-weight-600 mb-0">{{ element.endDate | date:"dd.MM.yyyy, hh:mm a" :"GMT+0"
                                }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="element.cruiseStatus">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>directions_boat</mat-icon>
                        </span>Cruise
                    </h5>
                </div>
                <div class="card-body">
                    <div class="row mt-n3">
                        <div class="col-sm-4 mt-3"> <b>Cruise Details:</b>
                            <p class="font-weight-600 mb-0">{{ element.cruiseName }}</p>
                            <p class="font-weight-600 mb-0">{{ element.cruiseLine }}</p>
                            <p class="font-weight-600 mb-0">{{ element.cruiseShip }}</p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Departure:</b>
                            <p class="font-weight-600 mb-0">{{ element.deptDate | date:"dd.MM.yyyy" }}</p>
                            <p class="font-weight-600 mb-0">{{ element.departurePort }}</p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Return:</b>
                            <p class="font-weight-600 mb-0">{{ element.returnDate | date:"dd.MM.yyyy" }}</p>
                            <p class="font-weight-600 mb-0">{{ element.disembarkPort }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="element.flightStatus">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>flight</mat-icon>
                        </span>Flight
                    </h5>
                </div>
                <div class="card-body">
                    <div class="row mt-n3">
                        <div class="col-sm-4 mt-3"> <b>Flight Details:</b>
                            <p class="font-weight-600 mb-0">{{ element.carrier }}</p>
                            <p class="font-weight-600 mb-0">{{ element.number }}</p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Departure:</b>
                            <p class="font-weight-600 mb-0">{{ element.departDateTime | date:"dd.MM.yyyy, hh:mm a"
                                :"GMT+0" }}</p>
                            <p class="font-weight-600 mb-0">{{ element.departAirName }}
                                <span *ngIf="element.departAirCode != ''">({{ element.departAirCode }})</span>
                            </p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Arrival:</b>
                            <p class="font-weight-600 mb-0">{{ element.arriveDateTime | date:"dd.MM.yyyy, hh:mm a"
                                :"GMT+0" }}</p>
                            <p class="font-weight-600 mb-0">{{ element.arriveAirName }}
                                <span *ngIf="element.destinAirCode != ''">({{ element.destinAirCode }})</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="element.accomStatus">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>hotel</mat-icon>
                        </span>Accommodations
                    </h5>
                </div>
                <div class="card-body">
                    <div class="row mt-n3">
                        <div class="col-sm-4 mt-3"> <b>Accommodation:</b>
                            <p class="font-weight-600 mb-0">{{ element.accomName }}</p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Duration:</b>
                            <p class="font-weight-600 mb-0">{{ element.checkInDate | date:"dd.MM.yyyy" }}</p>
                            <p class="font-weight-600 mb-0">{{ element.numNights }} night(s)</p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Address:</b>
                            <p class="font-weight-600 mb-0" *ngIf="element.address != ''">{{ element.address }}</p>
                            <p class="font-weight-600 mb-0">{{ element.city }}</p>
                            <p class="font-weight-600 mb-0">{{ element.country }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="element.miscStatus">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>miscellaneous_services</mat-icon>
                        </span>Miscellaneous
                    </h5>
                </div>
                <div class="card-body">
                    <div class="row mt-n3">
                        <div class="col-sm-4 mt-3"> <b>Name:</b>
                            <p class="font-weight-600 mb-0">{{ element.name }}</p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Start Date:</b>
                            <p class="font-weight-600 mb-0">{{ element.startDateTime | date:"dd.MM.yyyy, hh:mm a":"GMT+0" }}</p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>End Date:</b>
                            <p class="font-weight-600 mb-0">{{ element.endDateTime | date:"dd.MM.yyyy, hh:mm a":"GMT+0" }}</p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Location:</b>
                            <p class="font-weight-600 mb-0">
                                <span *ngIf="element.location != ''">{{ element.location }}, </span>
                                <span *ngIf="element.country != ''">{{ element.country }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="element.transferStatus">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>airport_shuttle</mat-icon>
                        </span>Transfer
                    </h5>
                </div>
                <div class="card-body">
                    <div class="row mt-n3">
                        <div class="col-sm-4 mt-3">
                            <div *ngIf="element.VehicleType && element.VehicleType != ''" style="margin-bottom: 10px;">
                                <b>Vehicle:</b>
                            <p class="font-weight-600 mb-0">{{ element.VehicleType }}</p>
                            </div>
                            <div *ngIf="(element.emergencyResortContact && element.emergencyResortContact != '') || (element.emergencyPhoneNo && element.emergencyPhoneNo != '')">
                                <b>Emergency Contact:</b>
                                <p class="font-weight-600 mb-0">{{ element.emergencyResortContact }}</p>
                                <p class="font-weight-600 mb-0">{{ element.emergencyPhoneNo }}</p>
                            </div>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Pick Up:</b>
                            <p class="font-weight-600 mb-0">{{ element.pickUpDateTime | date:"dd.MM.yyyy, hh:mm a"
                                :"GMT+0" }}</p>
                            <p class="font-weight-600 mb-0">{{ element.pickUpLocation }}</p>
                            <p class="font-weight-600 mb-0">{{ element.pickUpInstructions }}</p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Drop Off:</b>
                            <p class="font-weight-600 mb-0">{{ element.dropOffDateTime | date:"dd.MM.yyyy, hh:mm a"
                                :"GMT+0" }}</p>
                            <p class="font-weight-600 mb-0">{{ element.dropOffLocation }}</p>
                            <p class="font-weight-600 mb-0">{{ element.dropOffInstructions }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="element.trainStatus">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>train</mat-icon>
                        </span>Train
                    </h5>
                </div>
                <div class="card-body">
                    <div class="row mt-n3">
                        <div class="col-sm-4 mt-3">
                            <span *ngIf="(element.trainNameNo && element.trainNameNo != '') || (element.carriageClass && element.carriageClass != '') || (element.seat && element.seat != '')">
                                <b>Train Details:</b>
                                <p class="font-weight-600 mb-0">{{ element.trainNameNo }}</p>
                                <p class="font-weight-600 mb-0" *ngIf="element.carriageClass && element.carriageClass != ''">Class: {{ element.carriageClass }}</p>
                                <p class="font-weight-600 mb-0" *ngIf="element.seat && element.seat != ''">Seat: {{ element.seat }}</p>
                            </span>
                        </div>

                        <div class="col-sm-4 mt-3"> <b>Departure:</b>
                            <p class="font-weight-600 mb-0">{{ element.departDateTime | date:"dd.MM.yyyy, hh:mm a"
                                :"GMT+0" }}</p>
                            <p class="font-weight-600 mb-0">{{ element.departStation }}</p>
                        </div>
                        <div class="col-sm-4 mt-3"> <b>Arrival:</b>
                            <p class="font-weight-600 mb-0">{{ element.arriveDateTime | date:"dd.MM.yyyy, hh:mm a"
                                :"GMT+0" }}</p>
                            <p class="font-weight-600 mb-0">{{ element.arriveStation }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="element.title">
                <div class="card-header">
                    <h5 class="m-0" style="display: flex;">
                        <span class="text-muted mr-2">
                            <mat-icon>extension</mat-icon>
                        </span>{{ element.title }}
                    </h5>
                </div>

                <div class="card-body">
                    <div class="row mt-n3">
                        <div class="col-sm-4 mt-3">
                            <b>Description:</b>
                            <div style="white-space: pre-line;">{{ element.description }}</div>
                        </div>

                        <div class="col-sm-4 mt-3">
                            <span *ngIf="element.endDateTime && element.endDateTime != ''">
                                <b>Start Date:</b>
                                <p class="font-weight-600 mb-0">{{ element.startDateTime | date:"dd.MM.yyyy, hh:mm a":"GMT+0" }}</p>
                            </span>
                        </div>
                        <div class="col-sm-4 mt-3">
                            <span *ngIf="element.endDateTime && element.endDateTime != ''">
                                <b>End Date:</b>
                                <p class="font-weight-600 mb-0">{{ element.endDateTime | date:"dd.MM.yyyy, hh:mm a":"GMT+0" }}</p>
                            </span>
                        </div>

                        <div style="display: flex; align-items: center; justify-content: flex-end; width: 100%;">
                            <div class="font-weight-600 mb-0" style="margin-right: 20px;">
                                <button mat-button color="warn" class="removeButton" (click)="archiveItineraryAddon(element)"><mat-icon>delete</mat-icon></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <button mat-button (click)="openItineraryDialog()" class="addButtons">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </main>
    <!-- Main Content End -->

    <!-- Footer -->
    <footer class="text-center mt-3">
        <p><strong>{{ branchData.branchName }}</strong><br>
            <span *ngFor="let addressLn of branchAddress; let last = last;">
                {{ addressLn }}<span *ngIf="!last">, <br></span>
            </span>
        </p>
        <hr>
        <div class="btn-group btn-group-sm d-print-none">
            <a href="javascript:window.print()" class="btn btn-light border text-black-50 shadow-none">
                <i class="fa fa-print"></i> Print
            </a>
        </div>
    </footer>
    <!-- Footer End -->

</div>

<ng-template #statusDialog>
    <div *ngIf="systemMessage != ''" class="successMessage">
      <p>{{systemMessage}}</p>
      <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
    </div>
  </ng-template>

<ng-template #itineraryDialog>
    <div>
        <form #itiForm="ngForm" id="ngForm" ngNativeValidate (ngSubmit)="createItineraryAddon(itiForm)">
            <h5 style="margin: 0px;">Start Date & Time</h5>
            <mat-form-field style="margin-right: 10px;">
                <input ngModel matInput name="startDate" [matDatepicker]="startDate" id="startDate">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="startHrs" style="width: 85px; margin-right: 10px;">
                <input matInput ngModel placeholder="Hrs" type="number" id="startDateHrs" (change)="leadingZeroCheck('startDateHrs')" name="startDateHrs" min="0" max="23" #startDateHrs="ngModel" style="width: 85px;">
            </mat-form-field>

            <mat-form-field class="startMin" style="width: 85px;">
                <input matInput ngModel placeholder="Min" type="number" id="startDateMin" (change)="leadingZeroCheck('startDateMin')" name="startDateMin" min="0" max="59" #startDateMin="ngModel" style="width: 85px;">
            </mat-form-field>

            <h5 style="margin: 0px;">End Date & Time</h5>
            <mat-form-field style="margin-right: 10px;">
                <input ngModel matInput name="endDate" [matDatepicker]="endDate" id="endDate">
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="endHrs" style="width: 85px; margin-right: 10px;">
                <input matInput ngModel placeholder="Hrs" type="number" id="endDateHrs" (change)="leadingZeroCheck('endDateHrs')" name="endDateHrs" min="0" max="23" #endDateHrs="ngModel" style="width: 85px;">
            </mat-form-field>

            <mat-form-field class="endMin" style="width: 85px;">
                <input matInput ngModel placeholder="Min" type="number" id="endDateMin" (change)="leadingZeroCheck('endDateMin')" name="endDateMin" min="0" max="59" #endDateMin="ngModel" style="width: 85px;">
            </mat-form-field>

            <h5 style="margin: 0px;">Title</h5>
            <mat-form-field style="width: 100%;">
                <input matInput required ngModel required id="title" name="title" #title="ngModel" minlength="1" maxlength="50">
            </mat-form-field>

            <h5 style="margin: 0px;">Notes</h5>
            <mat-form-field style="width: 100%;">
                <textarea style="min-width: 50%; overflow: hidden;" matInput maxlength="255" cdkTextareaAutosize ngModel name="description" #description="ngModel"
                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" required></textarea>
            </mat-form-field>

            <button mat-button color="primary" type="submit" class="itiButton">Save</button>
        </form>
    </div>
</ng-template>
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./itinerary.component.css']
})

export class ItineraryComponent implements OnInit {

  bookingUrl: any = ''; // Hold url so the user can press a button which will take him back to the booking info

  bookingData: any = {}; // Holds booking data in it
  elementData: any = []; // Holds elements data in it
  branchData: any = {}; // Holds branch data in it
  leadPaxData: any = {}; // Holds lead passenger in it
  logoUrl: any = ''; // Hold logo URL link

  customerAddress: any = []; // Each element in an array will correspond to one address line
  branchAddress: any = []; // Each element in an array will correspond to one address line
  elementSorted: any = {
    attractions: [], carHires: [], carParks: [], cruises: [], flights: [],
    hotels: [], miscs: [], transfers: [], trains: []
  };
  chronologicalOrder: any = []; // This is where we'll keep ALL elements in a earliest -> latest date
  itineraryAddons: any = [];

  systemMessage = ''; // System information / messages to the customers

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('statusDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('itineraryDialog') itineraryDialog!: TemplateRef<any>;

  constructor(private router: Router, private route: ActivatedRoute, private customerService: CustomerService, public dialog: MatDialog) { }

  ngOnInit(): void {
    if (window.history.state.bookingUrl !== undefined && window.history.state.skeleton !== undefined) {
      const state = window.history.state;
      this.bookingUrl = state.bookingUrl; // Assign booking url so we can go back to it whenever we want to
      this.bookingData = state.skeleton.bookingData; // Assign booking data to global variable here
      this.elementData = state.skeleton.elementData; // Assign element data to global variable here
      this.branchData = state.skeleton.branchDetails; // Assign branch data to global variable here

      // Append trading name logo url below
      if (this.bookingData.tradingNameId) {
        this.logoUrl = this.branchData?.tradingNames.find((name: any) => name.id === this.bookingData.tradingNameId)?.logoRef;
      }

      this.computeBranchAddress(this.branchData);
      this.getItineraryAddons().then(() => {
        this.computeAllElements(this.elementData).then(() => {
          if (this.branchData.itineraryOption === 'successive') {
            this.reorderDivElements(this.branchData);
          }
  
          if (state.skeleton.leadPaxDetails !== undefined) {
            this.leadPaxData = state.skeleton.leadPaxDetails; // Assign lead passenger data to global variable ehre
            this.computeCustAddress(this.leadPaxData);
          }
        });
      });
      
    } else {
      this.route.params.subscribe(params => {
        const bookingUrl = '/booking/' + params.publicRequest + '/' + params.publicKey;
        this.router.navigateByUrl(bookingUrl);
      });
    }
  }

  computeCustAddress(customer: any): void {
    this.customerAddress = []; // Reset by default (no snowballing)
    if (customer.addressLine1 !== null && customer.addressLine1 !== '') {
      this.customerAddress.push(customer.addressLine1);
    }
    if (customer.addressLine2 !== null && customer.addressLine2 !== '') {
      this.customerAddress.push(customer.addressLine2);
    }
    if (customer.addressLine3 !== null && customer.addressLine3 !== '') {
      this.customerAddress.push(customer.addressLine3);
    }
    if (customer.addressLine4 !== null && customer.addressLine4 !== '') {
      this.customerAddress.push(customer.addressLine4);
    }
    if (customer.postcode !== null && customer.postcode !== '') {
      this.customerAddress.push(customer.postcode);
    }
    if (customer.county !== null && customer.county !== '') {
      this.customerAddress.push(customer.county);
    }
    if (customer.country !== null && customer.country !== '') {
      this.customerAddress.push(customer.country);
    }
  }

  computeBranchAddress(branch: any): void {
    this.branchAddress = []; // Reset by default (no snowballing)
    if (branch.address1 !== null && branch.address1 !== '') {
      this.branchAddress.push(branch.address1);
    }
    if (branch.address2 !== null && branch.address2 !== '') {
      this.branchAddress.push(branch.address2);
    }
    if (branch.postcode !== null && branch.postcode !== '') {
      this.branchAddress.push(branch.postcode);
    }
    if (branch.county !== null && branch.county !== '') {
      this.branchAddress.push(branch.county);
    }
    if (branch.country !== null && branch.country !== '') {
      this.branchAddress.push(branch.country);
    }
  }

  getItineraryAddons(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.route.params.subscribe(params => {
        const request = { publicRequest: params.publicRequest, publicKey: params.publicKey };

        this.customerService.getItineraryAddons(request).then((output: any) => {
          // Assign itinerary addons to global variable and that's it!
          if (output.status === 'OK') { this.itineraryAddons = output.data; }
          resolve('');
        }).catch((error: any) => {
          resolve('Error');
        });
      });
    });
  }

  computeAllElements(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.elementSorted = { // Reset by default (no snowballing)
        attractions: [], carHires: [], carParks: [], cruises: [], flights: [],
        hotels: [], miscs: [], transfers: [], trains: []
      };

      data.forEach((element: any) => {
        // We are making sure that each element in each array has the same property - so we can easily sort by it
        element.attractions.forEach((ele: any) => { ele.chronoDate = ele.startDateTime; });
        element.carhires.forEach((ele: any) => { ele.chronoDate = ele.pickUpDate; });
        element.carparks.forEach((ele: any) => { ele.chronoDate = ele.startDate; });
        element.cruises.forEach((ele: any) => { ele.chronoDate = ele.deptDate; });
        element.flights.forEach((ele: any) => { ele.chronoDate = ele.departDateTime; });
        element.accoms.forEach((ele: any) => { ele.chronoDate = ele.checkInDate; });
        element.miscs.forEach((ele: any) => { ele.chronoDate = ele.startDateTime; });
        element.transfers.forEach((ele: any) => { ele.chronoDate = ele.pickUpDateTime; });
        element.trains.forEach((ele: any) => { ele.chronoDate = ele.departDateTime; });

        this.elementSorted.attractions = this.elementSorted.attractions.concat(element.attractions);
        this.elementSorted.carHires = this.elementSorted.carHires.concat(element.carhires);
        this.elementSorted.carParks = this.elementSorted.carParks.concat(element.carparks);
        this.elementSorted.cruises = this.elementSorted.cruises.concat(element.cruises);
        this.elementSorted.flights = this.elementSorted.flights.concat(element.flights);
        this.elementSorted.hotels = this.elementSorted.hotels.concat(element.accoms);
        this.elementSorted.miscs = this.elementSorted.miscs.concat(element.miscs);
        this.elementSorted.transfers = this.elementSorted.transfers.concat(element.transfers);
        this.elementSorted.trains = this.elementSorted.trains.concat(element.trains);
      });

      // We also want to chronogically set custom itinerary..
      this.itineraryAddons.forEach((element: any) => {
        element.chronoDate = element.startDateTime;
      });

      // tslint:disable-next-line:max-line-length
      this.elementSorted.attractions.sort((objA: any, objB: any) => new Date(objA.startDateTime).getTime() - new Date(objB.startDateTime).getTime());
      this.elementSorted.carHires.sort((objA: any, objB: any) => new Date(objA.pickUpDate).getTime() - new Date(objB.pickUpDate).getTime());
      this.elementSorted.carParks.sort((objA: any, objB: any) => new Date(objA.startDate).getTime() - new Date(objB.startDate).getTime());
      this.elementSorted.cruises.sort((objA: any, objB: any) => new Date(objA.deptDate).getTime() - new Date(objB.deptDate).getTime());
      // tslint:disable-next-line:max-line-length
      this.elementSorted.flights.sort((objA: any, objB: any) => new Date(objA.departDateTime).getTime() - new Date(objB.departDateTime).getTime());
      this.elementSorted.hotels.sort((objA: any, objB: any) => new Date(objA.checkInDate).getTime() - new Date(objB.checkInDate).getTime());
      this.elementSorted.miscs.sort((objA: any, objB: any) => new Date(objA.startDateTime).getTime() - new Date(objB.startDateTime).getTime());
      // tslint:disable-next-line:max-line-length
      this.elementSorted.transfers.sort((objA: any, objB: any) => new Date(objA.pickUpDateTime).getTime() - new Date(objB.pickUpDateTime).getTime());
      // tslint:disable-next-line:max-line-length
      this.elementSorted.trains.sort((objA: any, objB: any) => new Date(objA.departDateTime).getTime() - new Date(objB.arriveDateTime).getTime());

      /*
        ABOVE IS DONE FOR A SUCCESSIVE ORDERING (PRE-NOV'23 CHANGES)

        BELOW IS DONE FOR CHRONOLOGICAL ORDERING (PAST-NOV'23 CHANGES)
      */

      // Merge all arrays into a single array
      const mergedArray = [].concat(...Object.values(this.elementSorted));
      const mergedArrayWithAddons = mergedArray.concat(this.itineraryAddons);

      // Sort the merged array by the 'chronoDate' property
      mergedArrayWithAddons.sort((a, b) =>
        a.chronoDate === null ? (b.chronoDate === null ? 0 : -1) :
        b.chronoDate === null ? 1 : a.chronoDate.localeCompare(b.chronoDate)
      );

      this.chronologicalOrder = mergedArrayWithAddons;

      resolve('');
    });
  }

  reorderDivElements(branchData: any): void {
    // Assign all 'orderable' class into variables first
    const attractionDetails: any = document.getElementsByClassName('custColumn')[1];
    const carHireDetails: any = document.getElementsByClassName('custColumn')[2];
    const carParkDetails: any = document.getElementsByClassName('custColumn')[3];
    const cruiseDetails: any = document.getElementsByClassName('custColumn')[4];
    const flightDetails: any = document.getElementsByClassName('custColumn')[5];
    const hotelDetails: any = document.getElementsByClassName('custColumn')[6];
    const miscDetails: any = document.getElementsByClassName('custColumn')[7];
    const transferDetails: any = document.getElementsByClassName('custColumn')[8];
    const trainDetails: any = document.getElementsByClassName('custColumn')[9];

    // Change the order of divs below
    try {
      attractionDetails.style.order = branchData.publicElementsOrder.split(';')[1].split(',').indexOf('1');
      carHireDetails.style.order = branchData.publicElementsOrder.split(';')[1].split(',').indexOf('2');
      carParkDetails.style.order = branchData.publicElementsOrder.split(';')[1].split(',').indexOf('3');
      cruiseDetails.style.order = branchData.publicElementsOrder.split(';')[1].split(',').indexOf('4');
      flightDetails.style.order = branchData.publicElementsOrder.split(';')[1].split(',').indexOf('5');
      hotelDetails.style.order = branchData.publicElementsOrder.split(';')[1].split(',').indexOf('6');
      miscDetails.style.order = branchData.publicElementsOrder.split(';')[1].split(',').indexOf('7');
      transferDetails.style.order = branchData.publicElementsOrder.split(';')[1].split(',').indexOf('8');
      trainDetails.style.order = branchData.publicElementsOrder.split(';')[1].split(',').indexOf('9');
    } catch {
      attractionDetails.style.order = 1;
      carHireDetails.style.order = 2;
      carParkDetails.style.order = 3;
      cruiseDetails.style.order = 4;
      flightDetails.style.order = 5;
      hotelDetails.style.order = 6;
      miscDetails.style.order = 7;
      transferDetails.style.order = 8;
      trainDetails.style.order = 9;
    }
  }

  createItineraryAddon(form: NgForm): void {
    // 13.04.2023 - validate Hrs and Mins
    const startHrs = this.validateTime(form.value.startDateHrs, 'hrs');
    const startMin = this.validateTime(form.value.startDateMin, 'min');
    const endHrs = this.validateTime(form.value.endDateHrs, 'hrs');
    const endMin = this.validateTime(form.value.endDateMin, 'min');
    
    const startDate = this.convertDateMoment(form.value.startDate).toString() +
    ' ' + this.zeroBeforeNo(startHrs) + ':' + this.zeroBeforeNo(startMin) + ':00';
    const endDate = this.convertDateMoment(form.value.endDate).toString() +
    ' ' + this.zeroBeforeNo(endHrs) + ':' + this.zeroBeforeNo(endMin) + ':00';

    // Make sure each element's return date is after pick up date here
    if (new Date(endDate) < new Date(startDate)) {
      this.sendMessageToDialog('Start date must be before the end date');
      throw new Error('Bad dates!');
    }

    this.route.params.subscribe(params => {
      const request = {
        publicRequest: params.publicRequest, publicKey: params.publicKey,
        title: form.value.title, description: form.value.description,
        dateFrom: startDate, dateTo: endDate
      };

      this.customerService.createItineraryAddon(request).then((output: any) => {
        this.dialog.closeAll();
        if (output.status === 'OK') {
          this.sendMessageToDialog('Itinerary has been updated');
          this.ngOnInit();
        } else {
          this.sendMessageToDialog(output.status);
        }
      }).catch((error: any) => {
      });
    });
  }

  archiveItineraryAddon(element: any): void {
    if (confirm('Are you sure you want to remove following itinerary element?\n\n' + element.title)) {

      this.route.params.subscribe(params => {
        const request = {
          publicRequest: params.publicRequest, publicKey: params.publicKey,
          id: element.id, company: element.company, operation: element.operation,
          tradeCode: element.tradeCode, bookingReference: element.bookingReference
        };
  
        this.customerService.archiveItineraryAddon(request).then((output: any) => {
          if (output.status === 'OK') {
            this.sendMessageToDialog('Itinerary element has been removed');
            this.ngOnInit();
          } else {
            this.sendMessageToDialog(output.status);
          }
        }).catch((error: any) => {
        });
      });
    }
  }

  openItineraryDialog(): void {
    this.dialog.open(this.itineraryDialog, {panelClass: 'externalDialog'});
  }

  goBackToBooking(): void {
    this.router.navigateByUrl(this.bookingUrl);
  }

  sendMessageToDialog(systemMessage: any): void {
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.systemMessage = systemMessage; this.dialog.open(this.statusDialog);
  }

  convertDateMoment(date: any): any {
    if (date != null && date._i != null) {
      if (date._i.year != null) {
        return date._i.year + '-' + this.zeroBeforeNo(date._i.month + 1) + '-' + this.zeroBeforeNo(date._i.date);
      } else {
        return this.convertDate(date._i);
      }
    }
    return date;
  }

  leadingZeroCheck(divId: any): void {
    const divValue = (document.getElementById(divId) as HTMLInputElement).value;
    // Get the value from HTML by the div ID and make sure there is a leading 0
    // When single value is entered
    if (divValue.length === 1) {
      (document.getElementById(divId) as HTMLInputElement).value = '0' + divValue;
    } else if (divId.includes('Hrs') && (Number(divValue) < 0 || Number(divValue) > 23)) {
      (document.getElementById(divId) as HTMLInputElement).value = '00';
    } else if (divId.includes('Min') && (Number(divValue) < 0 || Number(divValue) > 59)) {
      (document.getElementById(divId) as HTMLInputElement).value = '00';
    }
  }

  validateTime(timeNo: any, timeType: any): any {
    if (timeType === 'hrs' && (Number(timeNo) < 0 || Number(timeNo > 23))) {
      return '0';
    } else if (timeType === 'min' && (Number(timeNo) < 0 || Number(timeNo > 59))) {
      return '0';
    } else {
      return timeNo;
    }
  }

  zeroBeforeNo(inputNo: any): string {
    if (inputNo === null || inputNo === undefined || inputNo === '') {
      inputNo = '00';
    } else if (inputNo < 10) {
      inputNo = '0' + inputNo;
    }
    return inputNo;
  }

  convertDate(date: any): string {
    return date.substring(6, 10) + '-' + date.substring(3, 5) + '-' + date.substring(0, 2);
  }
}


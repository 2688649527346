import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-bad-request',
  templateUrl: './bad-request.component.html',
  styleUrls: ['./bad-request.component.css', '../../customerFace.css']
})
export class BadRequestComponent implements OnInit {

  constructor(private router: Router,  private location: Location,
              private route: ActivatedRoute) { this.location.replaceState(''); }

  ngOnInit(): void {

  }

}

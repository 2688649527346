<div style="height: 100vh" *ngIf="!pageLoaded">
  <div class="loading-indicator"><mat-spinner style="margin:0 auto; margin-top: -37.5px;"></mat-spinner><div class="waitText">Finalising your payment..</div></div>
</div>

<div class="container" [@inAnimation] *ngIf="pageLoaded">
  <div class="messageBox">
    <p *ngIf="branchLogo != 'null'"><img src="https://sings-media.s3.eu-west-2.amazonaws.com/logo/{{branchLogo}}" width="155px" style="mix-blend-mode: multiply;"></p>

    <h1 class="blackFont">
      <span *ngIf="customerTitle != ''">{{ customerTitle }} </span>
      <span>{{ customerName }}</span></h1>

    <h2>Thank you for your payment of <span class="blackFont">{{ amount | currency:currency }}</span><span *ngIf="description != ''"> for '<span class="blackFont">{{ description }}</span>'</span>.</h2>

    <h2 *ngIf="!fakeEmail">You will receive a receipt shortly.</h2>

    <h2 *ngIf="fakeEmail">The proof of payment will be sent directly to the shop.</h2>

    <h2 *ngIf="branchEmail && branchPhone" style="max-width: 700px;">
      If you have any questions, please contact us on
      <span class="blackFont">{{ branchEmail }}</span> or by phone
      <span class="blackFont">{{ branchPhone }}</span>.
    </h2>
    <h2 *ngIf="branchEmail && !branchPhone" style="max-width: 700px;">
      If you have any questions, please contact us on
      <span class="blackFont">{{ branchEmail }}</span>.
    </h2>
    <h2 *ngIf="!branchEmail && branchPhone" style="max-width: 700px;">
      If you have any questions, please contact us by phone
      <span class="blackFont">{{ branchPhone }}</span>.
    </h2>
    
    <br>
    <h3>Kind Regards,</h3>
    <h3>{{branchName}} Team</h3>

    <div *ngIf="systemMessage != ''">
      <br><br>
      <h3><u>{{systemMessage}}</u></h3>
    </div>
  </div>
</div>
